import React, { useState, useEffect, useRef, useContext } from 'react';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { RemindersList } from 'components/reminders-component/reminders-list';
import { RemindersContext } from 'contexts/RemindersContext';
import { Button } from 'components/ui/button';
import { PlusIcon } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import ResponsiveModal from 'components/reminders-component/components/responsive-modal';
import { ReminderCreate } from '../../components/reminders-component/reminder-create';
import { Text } from 'components/catalyst/text';

export function CustomerReminders() {
  const location = useLocation();
  const { reminders } = useContext(RemindersContext);
  const [isOpen, setIsOpen] = useState(false);

  const customerPathMatch = location.pathname.match(/^\/customer\/(.+)/);
  const customerId = customerPathMatch ? customerPathMatch[1] : null;

  const safeReminders = Array.isArray(reminders) ? reminders : [];

  const filteredByAccount = safeReminders?.filter(note =>
    customerId ? note.custcd === customerId : true
  );

  const filteredActive = filteredByAccount.filter(note => note.status === 'active');

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="mt-4 lg:mt-0">
      <Card>
        <CardHeader>
          <h2 className="text-lg font-semibold">Reminders</h2>
        </CardHeader>
        <CardContent className="space-y-4">
          {filteredActive.length === 0 ? (
            <Text>There are no reminders.</Text>
          ) : (
            <RemindersList items={filteredActive} scrollHeight={326} className="h-64 overflow-y-auto" />
          )}
        </CardContent>
        <CardFooter>
          <ResponsiveModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            trigger={
              <Button variant="outline" className="mt-0 mb-2 w-full" onClick={() => setIsOpen(true)}>
                <PlusIcon className="h-4 w-4 mr-2" />
                Create New Reminder
              </Button>
            }
            title="Create a New Reminder"
            description={<ReminderCreate customerId={customerId} handleClose={handleClose} setIsOpen={setIsOpen} />}
          />
        </CardFooter>
      </Card>
    </div>
  );
}