import React, { useState, useContext, useEffect } from 'react';
import { Card, CardHeader } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { DescriptionDetails, DescriptionList, DescriptionTerm } from 'components/catalyst/description-list';
import { Pencil, X } from 'lucide-react';
import { Input } from 'components/ui/input';
import { DataContext } from 'contexts/DataContext';

const fieldConfigs = [
  { label: 'Business Phone Number', fieldName: 'phone' },
  { label: 'Business Email', fieldName: 'email' },
  { label: 'Business Address', fieldName: 'address' },
  { label: 'Point of Contact Name', fieldName: 'contactName' },
  { label: 'Point of Contact Email', fieldName: 'contactEmail' },
  { label: 'Point of Contact Phone Number', fieldName: 'contactPhone' },
  { label: 'Monthly Minimum Spend Potential', fieldName: 'minSpend' }
];

export function CustomerDetails() {
  const { customerConfig, customerProfilePage, editCustomerConfig } = useContext(DataContext);

  const [isEditing, setIsEditing] = useState(false);
  const [fields, setFields] = useState({});
  const [originalFields, setOriginalFields] = useState({});

  useEffect(() => {
    const initialFields = {
      phone: customerConfig.cust_phone || '',
      email: customerConfig.cust_email || '',
      address: customerConfig.cust_address || '',
      contactName: customerConfig.poc_name || '',
      contactEmail: customerConfig.poc_email || '',
      contactPhone: customerConfig.poc_number || '',
      minSpend: customerConfig.min_spend_potential || customerProfilePage.plannedmonthlypurchase_min || ''
    };
    setFields(initialFields);
    setOriginalFields(initialFields);
  }, [customerConfig, customerProfilePage]);

  const handleInputChange = (field, value) => {
    setFields({ ...fields, [field]: value });
  };

  const handleSave = () => {
    setIsEditing(false);

    const payload = {
      cust_phone: fields.phone,
      cust_email: fields.email,
      cust_address: fields.address,
      poc_name: fields.contactName,
      poc_email: fields.contactEmail,
      poc_number: fields.contactPhone,
      min_spend_potential: fields.minSpend,
      custcd: customerConfig.custcd
    };

    editCustomerConfig(payload);
  };

  const handleCancel = () => {
    setFields(originalFields);
    setIsEditing(false);
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <div className="flex flex-row justify-between items-center">
            <h2 className="text-lg font-semibold">Business Details</h2>
            {!isEditing ? (
              <Pencil onClick={() => setIsEditing(true)} className='w-4 text-gray-500 cursor-pointer' />
            ) : (
              <X onClick={handleCancel} className='w-4 text-gray-500 cursor-pointer' />
            )}
          </div>
          <DescriptionList>
            {fieldConfigs.map(({ label, fieldName }) => (
              <React.Fragment key={fieldName}>
                <DescriptionTerm>{label}</DescriptionTerm>
                <DescriptionDetails className="pl-0 sm:pl-4 flex items-center space-x-2 pb-0 pt-0 sm:pb-2 sm:pt-2">
                  {!isEditing ? (
                    <span>{fieldName === 'minSpend' ? fields[fieldName]?.toLocaleString() : fields[fieldName]}</span>
                  ) : (
                    <Input
                      type="text"
                      value={fields[fieldName]}
                      onChange={(e) => handleInputChange(fieldName, e.target.value)}
                    />
                  )}
                </DescriptionDetails>
              </React.Fragment>
            ))}
          </DescriptionList>
        </CardHeader>
        {isEditing && (
          <div className="flex justify-end p-4 pt-0">
            <>
              <Button onClick={handleCancel} size="sm" className="ml-auto" variant="outline">
                Cancel
              </Button>
              <Button onClick={handleSave} size="sm" className="ml-2">
                Save
              </Button>
            </>
          </div>
        )}
      </Card>
    </div>
  );
}
