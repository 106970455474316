import React, { useState, useEffect, useRef, useContext } from 'react';
import { ChatContext } from "../contexts/ChatContext";

export function useWebSocketMessage() {
    const { startStreaming, setMessages, messages, setGhostMessage, salesAgentCD,
        setTypingIndicator, setStartStreaming, lastUserMessage, stopActivated, oktaId,
        BASE_CHATBOT_URL, setStopActivated, chatSessionId, currentCustomer } = useContext(ChatContext);
    
    const API_PREFIX = 'wss://'
    const ENDPOINT = '/stream';

    const payload = {
        user_query: lastUserMessage, 
        window_location: window.location.href,
        salesagentcd: salesAgentCD,
        custcd: currentCustomer ? currentCustomer.custcd : null,
        custname: currentCustomer ? currentCustomer.custname : null,
        session_uuid: chatSessionId,
        oktaid: oktaId
    };
    
    const wsRef = useRef(null);

    useEffect(() => {
        if (startStreaming) {
            console.log(payload)
            const wsUrl = API_PREFIX + BASE_CHATBOT_URL + ENDPOINT;
            const ws = new WebSocket(wsUrl);
            wsRef.current = ws;

            ws.onopen = () => {
                console.log("WebSocket connection established.");
                ws.send(JSON.stringify(payload));
            };

            ws.onmessage = (event) => {
                const response = JSON.parse(event.data);
                console.log(response)
                switch (response.type) {
                    case 'middle_message':
                        const middleMessage = {
                            type: "middle",
                            text: response.message,
                          }
                        setMessages([...messages, middleMessage])
                        break;
                    case 'message':
                        const newMessage = {
                            type: "ai",
                            message_id: response.message_id || '',
                            text: response.message                   
                          };
                        setMessages((prevMessages) => [...prevMessages, newMessage]);
                        setGhostMessage(false);
                        setTypingIndicator(false)
                        break;
                }
            };

            ws.onerror = (error) => {
                setStartStreaming(false)
                setGhostMessage(false);
                setTypingIndicator(false)
            };

            ws.onclose = (event) => {
                console.log("WebSocket connection closed:", event.reason);
                setStartStreaming(false)
                setGhostMessage(false);
                setTypingIndicator(false)
            };

            return () => {
                ws.close();
            };
        };
    }, [startStreaming]); 

    useEffect(() => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.close();
            setStartStreaming(false); 
            setGhostMessage(false)
            setTypingIndicator(false)
            setStopActivated(false)
        }
    }, [stopActivated]);
}
