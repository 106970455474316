import React from 'react';
import ReactDOM from 'react-dom/client';
import { RenderApp } from './OktaSetup'
import { AppProvider } from 'contexts/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'next-themes';
import './Datadog';  
import { Toaster } from "sonner"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster />
    <ThemeProvider attribute="class" defaultTheme="light" enableSystem>
    <BrowserRouter>
      <AppProvider>
        <RenderApp />
      </AppProvider>
    </BrowserRouter>
    </ThemeProvider>
  </>
);
