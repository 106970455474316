import React, { useState, useContext, useMemo } from "react";
import { Card, CardHeader, CardContent } from 'components/ui/card';
import { Button } from 'components/catalyst/button';
import { Textarea } from 'components/catalyst/textarea';
import { Label, Field } from 'components/catalyst/fieldset';
import { Bold, Italic, Underline, Sparkles, Copy } from "lucide-react";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/ui/toggle-group";
import { SearchAccountSwitch } from "components/layout/SearchAccountSwitch";
import { ChatContext } from "contexts/ChatContext";
import { AppContext } from "contexts/AppContext";
import { ThumbsDown, ThumbsUp } from "lucide-react";

export default function EmailPage() {
  const [selectedAccount, setSelectedAccount] = useState('');
  const { generateEmail, outputText, emailLoading, emailFeedback, handleEmailFeedback } = useContext(ChatContext);
  const { logEvent } = useContext(AppContext);

  const [promptText, setPromptText] = useState('');

  const handleInputChange = (e) => {
    setPromptText(e.target.value);
  };

  const handleFormat = (command) => {
    document.execCommand(command, false, null);
  };

  const copyToClipboard = () => {
    logEvent('Email', 'Copy to Clipboard', {"email": outputText, "prompt": promptText} )

    const outputElement = document.getElementById("outputText");
    const range = document.createRange();
    range.selectNodeContents(outputElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand("copy");
      alert("Copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }

    selection.removeAllRanges(); 
  };

  const formattedEmail = useMemo(() => outputText.replace(/\n/g, '<br>'), [outputText]);

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full pt-4">
        <Card className="w-[800px]">
          <CardHeader>
            <h2 className="text-lg font-semibold">Email Generator</h2>
          </CardHeader>
          <CardContent>
            <Field className="mb-4">
              <Label>Select a Customer:</Label>
              <SearchAccountSwitch className="mt-4 z-50" selectedAccount={selectedAccount} setSelectedAccount={setSelectedAccount} />
            </Field>
            <Field className="mb-4">
              <Label>Specify the email you'd like to generate:</Label>
              <Textarea 
                name="input" 
                placeholder="Example: 'Write me an email addressing the customer's SRS anniversary.'" 
                value={promptText} 
                onChange={handleInputChange} 
              />
            </Field>
            <Button className="w-full sm:w-auto" onClick={() => generateEmail(selectedAccount, promptText)}>
              <Sparkles className='w-4' />
              {emailLoading ? (
                  <span className="ml-2">Generating...</span>
              ) : (
                <span className="ml-2">Generate Email</span>
              )}
            </Button>
            <div
              id="outputText"
              className="border border-gray-300 p-4 mt-4 text-sm"
              contentEditable={true}
              suppressContentEditableWarning={true}
              style={{ minHeight: "300px", overflowY: "auto" }}
              dangerouslySetInnerHTML={{ __html: formattedEmail }}
            />
            <div className="flex items-center justify-between gap-4 mt-4">
              <ToggleGroup size={"sm"} type="multiple">
                <ToggleGroupItem value="bold" aria-label="Toggle bold" onClick={() => handleFormat('bold')}>
                  <Bold className="h-4 w-4" />
                </ToggleGroupItem>
                <ToggleGroupItem value="italic" aria-label="Toggle italic" onClick={() => handleFormat('italic')}>
                  <Italic className="h-4 w-4" />
                </ToggleGroupItem>
                <ToggleGroupItem value="underline" aria-label="Toggle underline" onClick={() => handleFormat('underline')}>
                  <Underline className="h-4 w-4" />
                </ToggleGroupItem>
              </ToggleGroup>
              <div className="flex items-center gap-2">
                <button
                  aria-label="Thumb Up"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => handleEmailFeedback(true)} 
                >
                  <ThumbsUp
                    className="w-5 h-5 mr-1"
                    fill={emailFeedback === true ? "currentColor" : "none"}
                  />
                </button>
                <button
                  aria-label="Thumb Down"
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => handleEmailFeedback(false)} 
                >
                  <ThumbsDown
                    className="w-5 h-5 mr-1"
                    fill={emailFeedback === false ? "currentColor" : "none"}
                  />
                </button>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <Button className="w-full sm:w-auto" onClick={copyToClipboard}>
                <Copy className="w-4" />Copy to Clipboard
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}