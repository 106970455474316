import React, { useState, useContext } from 'react';
import { Text } from 'components/catalyst/text'
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'
import { Badge } from 'components/ui/badge'
import { useParams } from 'react-router-dom';
import { DataContext } from 'contexts/DataContext';
import { formatValue } from 'utils/formatValue'

export function CustomerProfile() {
    const { customerProfilePage,customerInsights } = useContext(DataContext);

    const generateDescription = () => {
        const name = customerProfilePage?.custname || "Unknown Customer";
        const tenure = customerProfilePage?.tenureyears
            ? `${customerProfilePage.tenureyears.toFixed(0)} years`
            : "unknown tenure";
        const daysSinceLastPurchase = customerProfilePage?.days_since_last_invoice || "an unknown number of";
        const aov = customerProfilePage?.aov_so_t90d_cy 
            ? `${formatValue(customerProfilePage.aov_so_t90d_cy)}`
            : "an unknown average order value";
        const salesYTD = customerProfilePage?.sales_ytd_cy 
            ? `${formatValue(customerProfilePage.sales_ytd_cy)}`
            : "an unknown amount";
        const monthlyPurchaseGoal = customerProfilePage?.plannedmonthlypurchase_min 
            ? `${formatValue(customerProfilePage.plannedmonthlypurchase_min)}`
            : "an unknown goal";
        const latePayments = customerProfilePage?.late_payments?.length > 0
            ? `They have had ${customerProfilePage.late_payments.length} late payments.`
            : "They have no late payments on record.";

        return `${name}, a customer for ${tenure}, has not made a purchase in ${daysSinceLastPurchase} days. Their average order value over the past 90 days is ${aov}, contributing to year-to-date sales of ${salesYTD}. Their planned monthly purchase goal is ${monthlyPurchaseGoal}. ${latePayments}`;
    };

    const description = customerInsights[0]?.cosailor_description || generateDescription();

    return (
        <div>
            <Card>
                <CardHeader>
                    <h2 className="text-lg font-semibold">Customer Profile</h2>
                </CardHeader>
                <CardContent>
                    <Text>
                        {description}
                    </Text>
                </CardContent>
                <CardFooter className="flex flex-wrap gap-1">
                    {customerProfilePage?.tags?.map((tag) => (
                    <Badge key={tag} variant="outline">{tag}</Badge>
                    ))}
                </CardFooter>
            </Card>
        </div>
  )
}