"use client"

import { useState } from 'react'
import { Button } from "components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Badge } from "components/ui/badge"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "components/ui/dialog"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Textarea } from "components/ui/textarea"
import { ArrowLeft, MessageSquare, ArrowUpCircle, Pencil, Upload } from "lucide-react"
import { Link } from 'react-router-dom'

const userProfile = {
  name: "Jane Doe",
  username: "jane_doe",
  avatar: "/placeholder.svg",
  bio: "Roofing expert with 10+ years of experience. Always looking for innovative solutions!",
  joinDate: "Joined January 2022",
  stats: {
    posts: 47,
    comments: 152,
    likes: 308
  }
}

const userPosts = [
  {
    id: 1,
    content: "Just completed a challenging metal roof installation. Any tips for improving efficiency on these types of projects?",
    time: "2d ago",
    votes: 24,
    comments: 8,
    topics: ["Technical", "Efficiency"]
  },
  {
    id: 2,
    content: "Excited to try out the new eco-friendly shingles from GreenRoof. Has anyone had experience with their products?",
    time: "1w ago",
    votes: 31,
    comments: 12,
    topics: ["Product", "Sustainability"]
  },
  {
    id: 3,
    content: "Looking for recommendations on the best project management software for small roofing businesses. What's working well for you?",
    time: "2w ago",
    votes: 18,
    comments: 7,
    topics: ["Business", "Software"]
  }
]

function EditProfileDialog({ profile, onSave, open, onOpenChange }) {
  const [editedProfile, setEditedProfile] = useState(profile)
  const [previewUrl, setPreviewUrl] = useState(profile.avatar)

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
      const url = URL.createObjectURL(file)
      setPreviewUrl(url)
      // Here you would typically upload the file to your server
      // and get back a URL to store in the profile
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    onSave(editedProfile)
    onOpenChange(false)
  }

  return (
    <DialogContent className="sm:max-w-[425px]">
      <DialogHeader>
        <DialogTitle>Edit Profile</DialogTitle>
      </DialogHeader>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label>Profile Picture</Label>
          <div className="flex items-center space-x-4">
            <Avatar className="h-20 w-20">
              <AvatarImage src={previewUrl} alt={editedProfile.name} />
              <AvatarFallback>{editedProfile.name[0]}</AvatarFallback>
            </Avatar>
            <div className="flex flex-col space-y-2">
              <Label htmlFor="avatar" className="cursor-pointer">
                <div className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground hover:bg-primary/90">
                  <Upload className="mr-2 h-4 w-4" />
                  Upload New Picture
                </div>
              </Label>
              <Input 
                id="avatar"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageUpload}
              />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            value={editedProfile.name}
            onChange={(e) => setEditedProfile({ ...editedProfile, name: e.target.value })}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="username">Username</Label>
          <div className="flex">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-input bg-muted text-muted-foreground text-sm">
              @
            </span>
            <Input
              id="username"
              className="rounded-l-none"
              value={editedProfile.username}
              onChange={(e) => setEditedProfile({ ...editedProfile, username: e.target.value })}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="bio">Bio</Label>
          <Textarea
            id="bio"
            rows={4}
            value={editedProfile.bio}
            onChange={(e) => setEditedProfile({ ...editedProfile, bio: e.target.value })}
            placeholder="Tell us about yourself..."
          />
        </div>

        <div className="flex justify-end space-x-4">
          <Button type="submit">Save Changes</Button>
        </div>
      </form>
    </DialogContent>
  )
}

export default function WCProfilePage() {
  const [activeTab, setActiveTab] = useState("posts")
  const [profile, setProfile] = useState(userProfile)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleSaveProfile = (updatedProfile) => {
    setProfile(updatedProfile)
    // Here you would typically send the updated profile to your server
    console.log('Updated profile:', updatedProfile)
  }

  return (
    <div className="container max-w-2xl mx-auto px-4 py-8">
      <Link to="/water-cooler" className="inline-flex items-center text-sm text-muted-foreground hover:text-primary mb-6">
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back to Water Cooler
      </Link>
      
      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <div className="flex items-center space-x-4">
            <Avatar className="h-20 w-20">
              <AvatarImage src={profile.avatar} alt={profile.name} />
              <AvatarFallback>{profile.name[0]}</AvatarFallback>
            </Avatar>
            <div>
              <CardTitle className="text-2xl font-bold">{profile.name}</CardTitle>
              <p className="text-sm text-muted-foreground">@{profile.username}</p>
            </div>
          </div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button variant="ghost" size="icon">
                <Pencil className="h-5 w-5" />
                <span className="sr-only">Edit Profile</span>
              </Button>
            </DialogTrigger>
            <EditProfileDialog 
              profile={profile} 
              onSave={handleSaveProfile} 
              open={isDialogOpen} 
              onOpenChange={setIsDialogOpen}
            />
          </Dialog>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground mb-4">{profile.bio}</p>
          <p className="text-xs text-muted-foreground mb-4">{profile.joinDate}</p>
          <div className="flex space-x-4 text-sm text-muted-foreground mb-6">
            <span>{profile.stats.posts} posts</span>
            <span>{profile.stats.comments} comments</span>
            <span>{profile.stats.likes} likes</span>
          </div>
          
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="posts">Posts</TabsTrigger>
              <TabsTrigger value="activity">Activity</TabsTrigger>
            </TabsList>
            <TabsContent value="posts" className="space-y-4 mt-4">
              {userPosts.map((post) => (
                <Card key={post.id}>
                  <CardContent className="p-4">
                    <p className="text-sm mb-2">{post.content}</p>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {post.topics.map(topic => (
                        <Badge key={topic} variant="secondary">{topic}</Badge>
                      ))}
                    </div>
                    <div className="flex items-center justify-between text-sm text-muted-foreground">
                      <span>{post.time}</span>
                      <div className="flex items-center space-x-2">
                        <Button variant="ghost" size="sm">
                          <ArrowUpCircle className="h-4 w-4 mr-1" />
                          {post.votes}
                        </Button>
                        <Button variant="ghost" size="sm">
                          <MessageSquare className="h-4 w-4 mr-1" />
                          {post.comments}
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </TabsContent>
            <TabsContent value="activity" className="mt-4">
              <p className="text-sm text-muted-foreground">Recent activity will be displayed here.</p>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  )
}