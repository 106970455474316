import React, { useContext, useState } from 'react';
import { Drawer, Card } from '@mui/material';
import { ChatContext } from 'contexts/ChatContext';
import ChatWindow from './chatWindow';
import Input from './components/Input';
import { TopHeading } from './components/Heading';
import ProgressCircle from './components/Progress';
import './styles/Layout.css';
import './styles/Themes.css';

const DesktopChatLayout = ({ open, handleToggle }) => {
  const { typingIndicator } = useContext(ChatContext);
  const [chatMode, setChatMode] = useState('global');

  return (
    <Drawer
      sx={{
        zIndex: 2001,
        '& .MuiDrawer-paper': {
          overflow: 'visible',
          touchAction: 'auto',
          pointerEvents: 'auto',
        },
        pointerEvents: 'auto',
      }}
      anchor="right"
      open={open}
      variant="persistent"
      PaperProps={{
        sx: {
          width: 400,
          pointerEvents: 'auto',
        },
      }} 
    >
      {open && (
        <Card
          sx={{
            border: 'none',
            borderRadius: 0,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiCardHeader-root': { color: 'background.paper' },
          }}
          content={false}
        >
          <div id="pixel-container">
            <div className="vsa-chat-box-container">
              <TopHeading toggle={handleToggle} />
              {/* // CUSTOMER <> GLOBAL MODE
              <div className="flex justify-between items-center p-2 border-b">
                <span>Mode:</span>
                <select
                  onChange={(e) => setChatMode(e.target.value)}
                  value={chatMode}
                  className="border ro unded p-1"
                >
                  <option value="global">Global</option>
                  <option value="customer">Customer</option>
                </select>
              </div>
              */}
              {chatMode === 'customer' && (
                <div className="bg-blue-100 text-blue-700 p-2 text-center font-semibold">
                  Customer Mode On
                </div>
              )}
              <div className="vsa-window">
                <ChatWindow />
              </div>
              {typingIndicator ? <ProgressCircle /> : <Input />}
            </div>
          </div>
        </Card>
      )}
    </Drawer>
  );
};

export default DesktopChatLayout;
