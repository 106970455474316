import React, { useState, useEffect, useRef, useContext } from 'react'
import { cn } from 'utils/utils'
import { AppContext } from 'contexts/AppContext';
import { CheckCircle2 } from 'lucide-react'
import { SnoozeOutlined } from "@mui/icons-material";

export function InsightCard({ item }) {
  
  return (
      <>
        <div className={cn(
          'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
          item.selected && 'bg-muted'
          )}>
          <div className="flex w-full flex-col gap-1">
            <div className="flex items-center">
              <div className="font-semibold">
                {typeof item.cosailor_task[0] === 'object' ? JSON.stringify(item.cosailor_task[0]) : item.cosailor_task[0]}
                {item.status === "accepted" && (
                  <CheckCircle2 className="inline-block align-middle ml-2 h-4 w-4 text-green-500" />
                )}
                {item.status === "snoozed" && (
                  <SnoozeOutlined className="inline-block align-middle ml-2" fontSize="small"/>
                )}
              </div>
              <div className={cn("ml-auto text-xs", item.selected ? "text-foreground" : "text-muted-foreground")}>
                {new Date(item.compute_time).toLocaleDateString()}
              </div>
            </div>
            <div className="text-xs font-medium">{item.subject}</div>
          </div>
          <div className="line-clamp-2 text-xs text-muted-foreground">
            {typeof item.cosailor_task[1] === 'object' ? JSON.stringify(item.cosailor_task[1]) : item.cosailor_task[1]}
          </div>
        </div>
    </>
  )
}
