"use client"

import { useState } from 'react'
import { Button } from "components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Label } from "components/ui/label"
import { Switch } from "components/ui/switch"
import { toast } from "components/ui/use-toast"
import { ArrowLeft } from "lucide-react"
import { Link } from 'react-router-dom'

export default function SettingsPage() {
  const [anonymousPosting, setAnonymousPosting] = useState(false)

  const handleSaveSettings = () => {
    // Here you would typically save the settings to your backend
    toast({
      title: "Settings saved",
      description: `Anonymous posting is now ${anonymousPosting ? 'enabled' : 'disabled'}.`,
    })
  }

  return (
    <div className="container max-w-md mx-auto px-4 py-8">
      <Link to="/water-cooler" className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-4">
        <ArrowLeft className="mr-2 h-4 w-4" />
        Back
      </Link>
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Settings</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="flex items-center justify-between">
            <Label htmlFor="anonymous-posting" className="text-sm">
              Anonymous Posting
            </Label>
            <Switch
              id="anonymous-posting"
              checked={anonymousPosting}
              onCheckedChange={setAnonymousPosting}
            />
          </div>
          <p className="text-xs text-gray-500">
            When enabled, your name will not be displayed on your posts
          </p>
          <Button onClick={handleSaveSettings} className="w-full">Save Settings</Button>
        </CardContent>
      </Card>
    </div>
  )
}