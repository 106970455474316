import React, { useState, useEffect, useRef, useContext } from 'react'
import { cn } from 'utils/utils'
import { Badge } from 'components/ui/badge'
import { ScrollArea } from 'components/ui/scroll-area'
import { BookmarkFilledIcon } from '@radix-ui/react-icons'
import { Archive, Building2Icon, BookmarkIcon, NotebookPenIcon, Trash2Icon, CheckCircle2 } from 'lucide-react'
import { ExclamationCircleIcon } from '@heroicons/react/16/solid'
import { Text } from 'components/catalyst/text'
import { Button } from 'components/ui/button'
import { Link } from 'react-router-dom'
import { ReminderCreate } from './reminder-create'
import { AppContext } from 'contexts/AppContext';
import { RemindersContext } from 'contexts/RemindersContext';

import { LinkedContent } from './components/linked-content'
import { ModifyDropdown } from './components/modify-dropdown'

function getBadgeVariantFromLabel(label) {
  if (['voice', 'text'].includes(label.toLowerCase())) {
    return 'default'
  }

  if (['personal'].includes(label.toLowerCase())) {
    return 'outline'
  }

  return 'outline'
}

export function ReminderCard({ item }) {
  const { isMobile } = useContext(AppContext);

  return (
        <div className={cn(
            'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent',
            item.selected && 'bg-muted'
          )}>
            <div className="flex w-full flex-col gap-1">
                <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <div className="font-semibold">{item.header}</div>
                    {item.date_completed && <CheckCircle2 className="mr-1 h-4 w-4 text-green-500" />}
                    {item.viewed==null && <span className="inline-block align-middle ml-1 h-2 w-2  rounded-full bg-blue-600" />}
                    {item.is_priority && <ExclamationCircleIcon className="w-4 text-red-600" />}
                    {item.is_bookmark && <BookmarkFilledIcon className="ml-auto text-blue-500" />}
                </div>
                <div className={cn('ml-auto text-xs', item.selected ? 'text-foreground' : 'text-muted-foreground')}>
                    {new Date(item.date_created).toLocaleDateString()}
                </div>
                </div>
            </div>
            {(item.custname || item.custcd) && 
            <div className="text-xs font-medium">
                <div className="flex items-center text-s">
                <Building2Icon className="w-4 mr-2" />
                <Link to={`/customer/${item.custcd}`}>{item.custname || item.custcd}</Link>
                </div>
            </div>
            }
            <div className="line-clamp-2 text-xs text-muted-foreground">{item.description}</div>
            {item.tags && item.tags.length ? (
                <div className="flex items-center gap-2">
                {item.tags.map((label) => (
                    <Badge key={label} variant={getBadgeVariantFromLabel(label)}>
                    {label}
                    </Badge>
                ))}
                </div>
            ) : null}
        </div>
  )
}
