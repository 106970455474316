export const formatValue = (value) => {
    if (value === null || value === undefined || value === '') {
      return '\u00A0'; 
    } else if (value >= 1000 && value < 1000000) {
      return `$${(value / 1000).toFixed(1)}K`;
    } else if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else {
      return `$${Math.floor(value).toLocaleString()}`;
    }
};