import React, {useState, useEffect, useContext} from "react";
import { CheckIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Separator } from "components/ui/separator";
import { AppContext } from 'contexts/AppContext'

function DataTableFacetedFilter({ column, title, options }) {
  const { logEvent } = useContext(AppContext)
  const facets = column.getFacetedUniqueValues();
  const selectedValues = new Set(column.getFilterValue() || []);

  const handleSelect = (optionValue) => {
    logEvent('Customer Table', 'Filter', {"filter":optionValue})
    if (selectedValues.has(optionValue)) {
      selectedValues.delete(optionValue);
    } else {
      selectedValues.add(optionValue);
    }
    column.setFilterValue(Array.from(selectedValues));
  };

  return (
    <Popover>
      <PopoverTrigger asChild>             
        <Button variant="outline" size="sm" className="h-8 border-dashed w-full lg:w-auto">
          <PlusCircledIcon className="mr-2 h-4 w-4" />
          {title}
          {selectedValues.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge variant="secondary" className="rounded-sm px-1 font-normal lg:hidden">
                {selectedValues.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selectedValues.size > 2 ? (
                  <Badge variant="secondary" className="rounded-sm px-1 font-normal">
                    {selectedValues.size} selected
                  </Badge>
                ) : (
                  Array.from(selectedValues).map((value) => (
                    <Badge key={value} variant="secondary" className="rounded-sm px-1 font-normal">
                      {options.find((option) => option.value === value).label}
                    </Badge>
                  ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[360px] p-0" align="start">
        <Command>
          <CommandInput placeholder={`Search ${title}`} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => handleSelect(option.value)}
                  >
                    <div className={`mr-2 flex h-4 w-4 items-center justify-center rounded-sm border ${isSelected ? "bg-primary text-primary-foreground" : "opacity-50"}`}>
                      {isSelected && <CheckIcon className="h-4 w-4" />}
                    </div>
                    <span>{option.label}</span>
                    {facets.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandItem
                  onSelect={() => {column.setFilterValue(undefined); logEvent('Customer Table', 'Clear Filters','' )}}
                  className="justify-center text-center"
                >
                  Clear filters
                </CommandItem>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export default DataTableFacetedFilter;
