import { useState } from 'react'
import { Button } from "components/ui/button"
import { Card, CardContent, CardFooter } from "components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Badge } from "components/ui/badge"
import { Textarea } from "components/ui/textarea"
import { ArrowUpCircle, ArrowDownCircle, MessageSquare, Share2, Flag, MoreHorizontal, ChevronLeft, Bookmark } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"

export default function DetailedPost() {
  const [comment, setComment] = useState('')
  const [postVotes, setPostVotes] = useState(42)
  const [postDownvotes, setPostDownvotes] = useState(3)
  const [isPostUpvoted, setIsPostUpvoted] = useState(false)
  const [isPostDownvoted, setIsPostDownvoted] = useState(false)

  const post = {
    id: 1,
    title: "What's your go-to strategy for upselling premium roofing materials to hesitant customers?",
    content: "I've been struggling lately with convincing some of our more budget-conscious customers about the long-term benefits of investing in our premium roofing materials. They often balk at the higher upfront costs, even when I explain the durability and energy efficiency benefits.\n\nI'm looking for some fresh perspectives and strategies that have worked well for others in similar situations. Any specific talking points, demonstration techniques, or customer success stories that have been particularly effective?\n\nAlso, how do you handle objections related to price without coming across as pushy? I want to ensure our customers make informed decisions that they'll be happy with in the long run, but I also don't want to lose sales unnecessarily.\n\nAny insights or experiences you can share would be greatly appreciated!",
    author: "User 54321",
    authorAvatar: "/avatars/01.png",
    time: "2d ago",
    topics: ["Sales", "Product"],
    comments: [
      {
        id: 1,
        content: "I've found success in showing customers physical samples of premium materials side-by-side with standard options. Letting them see and feel the difference often helps justify the cost. Also, I create a simple cost comparison chart that shows the long-term savings in terms of durability and energy efficiency. Visual aids can be very persuasive!",
        author: "User 67890",
        authorAvatar: "/avatars/02.png",
        time: "1d ago",
        votes: 28,
        downvotes: 1
      },
      {
        id: 2,
        content: "One strategy that's worked well for me is to offer a financing option that spreads the cost over time. This can make the premium materials more accessible to budget-conscious customers. I also emphasize the increased home value and potential insurance premium reductions that come with higher-quality roofing.",
        author: "User 13579",
        authorAvatar: "/avatars/03.png",
        time: "22h ago",
        votes: 15,
        downvotes: 0
      },
      {
        id: 3,
        content: "I always start by asking customers about their long-term plans for the house. If they're planning to stay for a while, I focus on the comfort and peace of mind that comes with a premium roof. For those thinking of selling, I highlight how a high-quality roof can be a major selling point. Tailoring the pitch to their specific situation has been key for me.",
        author: "User 24680",
        authorAvatar: "/avatars/04.png",
        time: "10h ago",
        votes: 7,
        downvotes: 0
      }
    ]
  }

  const [comments, setComments] = useState(post.comments)

  const handlePostVote = (isUpvote) => {
    if (isUpvote) {
      if (isPostUpvoted) {
        setPostVotes(postVotes - 1)
        setIsPostUpvoted(false)
      } else {
        setPostVotes(postVotes + 1)
        setIsPostUpvoted(true)
        if (isPostDownvoted) {
          setPostDownvotes(postDownvotes - 1)
          setIsPostDownvoted(false)
        }
      }
    } else {
      if (isPostDownvoted) {
        setPostDownvotes(postDownvotes - 1)
        setIsPostDownvoted(false)
      } else {
        setPostDownvotes(postDownvotes + 1)
        setIsPostDownvoted(true)
        if (isPostUpvoted) {
          setPostVotes(postVotes - 1)
          setIsPostUpvoted(false)
        }
      }
    }
  }

  const handleCommentVote = (commentId, isUpvote) => {
    setComments(comments.map(comment => {
      if (comment.id === commentId) {
        if (isUpvote) {
          return { ...comment, votes: comment.votes + 1 }
        } else {
          return { ...comment, downvotes: comment.downvotes + 1 }
        }
      }
      return comment
    }))
  }

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (comment.trim() === '') return;

    const newComment = {
      id: comments.length + 1,
      content: comment,
      author: "Current User", // Replace with actual user data
      authorAvatar: "/avatars/default.png", // Replace with actual avatar
      time: "Just now",
      votes: 0,
      downvotes: 0
    };

    setComments([newComment, ...comments]);
    setComment('');
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <a href="/water-cooler" className="hover:underline mb-4 inline-block">
        &larr; Back to Water Cooler
      </a>

      <Card className="mb-6">
        <CardContent className="p-6">
          <div className="flex items-start justify-between mb-4">
            <div className="flex items-center">
              <Avatar className="h-10 w-10 mr-3">
                <AvatarImage src={post.authorAvatar} alt={post.author} />
                <AvatarFallback>{post.author[0]}</AvatarFallback>
              </Avatar>
              <div>
                <p className="font-semibold">{post.author}</p>
                <p className="text-sm text-gray-500">{post.time}</p>
              </div>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="sm">
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem>
                  <Bookmark className="mr-2 h-4 w-4" />
                  Save Post
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Share2 className="mr-2 h-4 w-4" />
                  Share
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Flag className="mr-2 h-4 w-4" />
                  Report
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <h1 className="text-2xl font-bold mb-4">{post.title}</h1>
          <div className="flex flex-wrap gap-2 mb-4">
            {post.topics.map(topic => (
              <Badge key={topic} variant="secondary">{topic}</Badge>
            ))}
          </div>
          <div className="prose max-w-none mb-4">
            {post.content.split('\n\n').map((paragraph, index) => (
              <p key={index} className="mb-4">{paragraph}</p>
            ))}
          </div>
          <div className="flex items-center space-x-4">
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={() => handlePostVote(true)}
              className={isPostUpvoted ? "text-green-600" : ""}
            >
              <ArrowUpCircle className="h-5 w-5 mr-1" />
              Upvote
            </Button>
            <span className="font-semibold">{postVotes - postDownvotes}</span>
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={() => handlePostVote(false)}
              className={isPostDownvoted ? "text-red-600" : ""}
            >
              <ArrowDownCircle className="h-5 w-5 mr-1" />
              Downvote
            </Button>
            <Button variant="ghost" size="sm">
              <MessageSquare className="h-5 w-5 mr-1" />
              {comments.length} Comments
            </Button>
          </div>
        </CardContent>
      </Card>

      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-4">Comments</h2>
        {comments.map((comment) => (
          <Card key={comment.id} className="mb-4">
            <CardContent className="p-4">
              <div className="flex items-start justify-between mb-2">
                <div className="flex items-center">
                  <Avatar className="h-8 w-8 mr-2">
                    <AvatarImage src={comment.authorAvatar} alt={comment.author} />
                    <AvatarFallback>{comment.author[0]}</AvatarFallback>
                  </Avatar>
                  <div>
                    <p className="font-semibold">{comment.author}</p>
                    <p className="text-sm text-gray-500">{comment.time}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => handleCommentVote(comment.id, true)}
                  >
                    <ArrowUpCircle className="h-4 w-4" />
                  </Button>
                  <span className="font-semibold">{comment.votes - comment.downvotes}</span>
                  <Button 
                    variant="ghost" 
                    size="sm" 
                    onClick={() => handleCommentVote(comment.id, false)}
                  >
                    <ArrowDownCircle className="h-4 w-4" />
                  </Button>
                </div>
              </div>
              <p className="text-sm">{comment.content}</p>
            </CardContent>
          </Card>
        ))}
      </div>

      <Card>
        <CardContent className="p-4">
          <form onSubmit={handleCommentSubmit} className="flex flex-col h-full">
            <Textarea
              placeholder="Add a comment..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={3}
              className="mb-2 flex-grow"
            />
              <Button type="submit">Post Comment</Button>
          </form>
        </CardContent>
      </Card>
    </div>
  )
}
