import React, { useState, useEffect, useRef, useContext } from 'react'
import { cn } from 'utils/utils'
import { Badge } from 'components/ui/badge'
import { ScrollArea } from 'components/ui/scroll-area'
import { BookmarkFilledIcon } from '@radix-ui/react-icons'
import { Archive, Building2Icon, BookmarkIcon, NotebookPenIcon, Trash2Icon } from 'lucide-react'
import { ExclamationCircleIcon } from '@heroicons/react/16/solid'
import { Text } from 'components/catalyst/text'
import { AppContext } from 'contexts/AppContext';
import { Link } from 'react-router-dom'
import { ActionItems } from './components/action-items'

export function CardPopUp({ item }) {
  const { logEvent } = useContext(AppContext);

  logEvent('Insights', 'Insight Viewed', { "insightid": String(item.task_id) });

  const renderFlags = (flags) => {
    return (
      <div>
        {Object.entries(flags).map(([key, value]) => (
          <div key={key}>
            <strong>{key.replace(/_/g, ' ')}:</strong> {value ? 'Yes' : 'No'}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
    <div className="flex h-full flex-col p-4 sm:p-0">
        <div className="flex justify-between items-start">
            <div className={cn("text-xs", item.selected ? "text-foreground" : "text-muted-foreground")}>
            {new Date(item.compute_time).toLocaleDateString()}
            </div>
        </div>
        <div className="py-3 text-left">
          <div className ="rounded-md border p-4 ">
            <ScrollArea className="w-full h-[300px]">
                <div className="font-bold mb-4">
                  {typeof item.cosailor_task[1] === 'object' 
                    ? renderFlags(item.cosailor_task[1])
                    : item.cosailor_task[1]}
                </div>
                {Array.isArray(item.nuclia_output) && item.nuclia_output.map((output, index) => (
                  <div key={index} className="mb-4">
                    <div className="font-semibold">• {output.point}</div>
                    <div className="text-sm text-muted-foreground">{output.explanation}</div>
                  </div>
                ))}
            </ScrollArea>
          </div>
        </div>
        <ActionItems item={item}/>
    </div>
    </>
  )
}
