import React, { useState, useContext } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { CreateVoice } from './components/create-voice';
import { CreateText } from './components/create-text';
import { Button } from "components/ui/button";
import { Switch } from "components/ui/switch";
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from 'contexts/AppContext';
import { SetDate } from './components/set-date';
import { RemindersContext } from 'contexts/RemindersContext';
import { toast } from 'sonner'; // Add this import

export function ReminderCreate({ selectedNote, customerId, handleClose, setIsOpen }) {
  const { createReminder, updateReminder, draftReminder } = useContext(RemindersContext);
  const { salesAgentCD } = useContext(AppContext);
  const [selectedAccount, setSelectedAccount] = useState(selectedNote?.custcd || customerId || '');
    
  const [errorMessage, setErrorMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);

  // State for note fields
  const [noteId, setNoteId] = useState(selectedNote?.remindersid || uuidv4());
  const [title, setTitle] = useState(selectedNote?.header || '');
  const [content, setContent] = useState(selectedNote?.description || '');
  const [isBookmark, setIsBookmark] = useState(selectedNote?.is_bookmark || false);
  const [reminderDate, setReminderDate] = useState(selectedNote?.date_due || null);
  const [selectedLabels, setSelectedLabels] = useState(selectedNote?.labels || []);

  const [transcript, setTranscript] = useState(selectedNote?.transcript || '');
  const [type, setType] = useState(selectedNote?.type || 'text');
  
  const handleSave = async (e, isDraft) => {
    e.preventDefault();

    if (isRecording){
      setErrorMessage(`Please stop the recording to proccess the text.`);
      return;
    }

    const missingFields = [];
    if (!title) missingFields.push('title');
    if (!content) missingFields.push('content');
    if (!reminderDate) missingFields.push('reminder date');
    if (!selectedAccount) missingFields.push('account');

    if (missingFields.length > 0) {
      setErrorMessage(`Please add ${missingFields.join(', ')}`);
      return;
    }

    setErrorMessage('');

    const note = {
      remindersid: noteId, 
      salesagentcd: salesAgentCD,
      custcd: selectedAccount,
      header: title,
      description: content,
      type: type,
      transcript: '',
      reminder_date: reminderDate
    };

    const updatedDate = new Date().toISOString()

    if (selectedNote) { // Update Current note
      console.log("UPDATE")
      await updateReminder({ ...note , updated_date: updatedDate});
      toast("Reminder has been updated", {
        description: "Your changes have been saved",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
    } else { // Create Draft
        if (isDraft){
          console.log("DRAFT")
          await createReminder({ ...note , creation_date: updatedDate});
          await draftReminder({ ...note , updated_date: updatedDate});
          toast("Draft has been saved", {
            description: "Moved to Drafts",
            action: {
              label: "Close",
              onClick: () => console.log("Close"),
            },
          });
        } else { // Create new note
          console.log("CREATE")
          await createReminder({ ...note , creation_date: updatedDate});
          toast("Reminder has been created", {
            description: "Moved to All Reminders",
            action: {
              label: "Close",
              onClick: () => console.log("Close"),
            },
          });
      }
    }
    // Reset the form after save
    setNoteId('');
    setTitle('');
    setContent('');
    setIsBookmark(false);
    setReminderDate(null);
    setSelectedLabels([]);
    setSelectedAccount('')

    // Close the dialog using handleClose and setIsOpen
    if (typeof handleClose === 'function') {
      handleClose();
    }
    if (typeof setIsOpen === 'function') {
      setIsOpen(false);
    }
  };

  return (
    <div className="flex h-full flex-col">
      <div className="p-4 sm:p-0">
        <form>
          <Tabs defaultValue="note" className="w-full">
            <TabsList>
              <TabsTrigger value="note">Add Reminder</TabsTrigger>
              <TabsTrigger value="voice">Add Voice Reminder</TabsTrigger>
            </TabsList>
            <TabsContent value="note">
              <CreateText
                title={title}
                setTitle={setTitle}
                content={content}
                setContent={setContent}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
              />
            </TabsContent>
            <TabsContent value="voice">
              <CreateVoice
                title={title}
                setTitle={setTitle}
                content={content}
                setContent={setContent}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                setType={setType}
                setTranscript={setTranscript}
                setIsRecording={setIsRecording}
                setErrorMessage={setErrorMessage}
              />
            </TabsContent>
          </Tabs> 
          {/* <div className="flex items-center flex-start mt-4">
            <p className="text-sm text-muted-foreground w-[130px]">Bookmark Note</p>
            <div className="flex space-x-2">
              <Switch
                id="bookmark"
                checked={isBookmark}
                onCheckedChange={setIsBookmark}
                aria-label="Bookmark Note"
              />
            </div>
          </div> */}
          <SetDate reminderDate={reminderDate} setReminderDate={setReminderDate}/>
          {errorMessage && (
            <div className="text-red-600 text-sm mt-4">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-end mt-4">
            {!selectedNote &&
            <Button
              onClick={(e) => handleSave(e, true)}
              size="sm"
              className="ml-auto"
              variant="outline"
            >
              Save Draft
            </Button>
            }
            <Button
              onClick={(e) => handleSave(e, false)}
              size="sm"
              className="ml-2"
            >
              {selectedNote ? 'Update Reminder' : 'Create Reminder'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}