import React, { useState, useContext } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { Sailboat } from 'lucide-react';
import { ChatContext } from 'contexts/ChatContext';
import { useTheme } from 'next-themes';

import MobileChatLayout from './mobileChatLayout';
import DesktopChatLayout from './desktopChatLayout';

const Cosailor = () => {  
  const { isMobile } = useContext(ChatContext);
  const [open, setOpen] = useState(false);
  const { theme } = useTheme(); // Access the theme from next-themes

  const handleToggle = () => {
    setOpen(!open);
  };

  const iconColor = theme === 'dark' ? '#FFFFFF' : '#000000'; // Set color based on theme

  return (
    <>
      {isMobile ? (
        <MobileChatLayout open={open} handleToggle={handleToggle} />
      ) : (
        <>
          <Tooltip title="Toggle Chat">
            <IconButton
              onClick={handleToggle}
              aria-label="settings toggler"
              sx={{
                color: iconColor, // Use the icon color based on theme
                bgcolor: open ? 'grey.100' : 'transparent',
                '&:hover': {
                  bgcolor: 'grey.200', // You can adjust this color as needed
                },
              }}
            >
              <Sailboat 
                className="w-6 h-6" 
                style={{ color: iconColor }} // Apply color directly to the icon
              />
            </IconButton>
          </Tooltip>
          <DesktopChatLayout open={open} handleToggle={handleToggle} />
        </>
      )}
    </>
  );
};

export default Cosailor;