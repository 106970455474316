"use client";

import React, { useState, useContext } from 'react';
import { Badge } from 'components/catalyst/badge';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
  PaginationEllipsis,
  createPaginationItems
} from 'components/ui/pagination';
import { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Card, CardHeader, CardContent } from 'components/ui/card';
import { Link } from 'react-router-dom';

const colorMapBackground = {
  'Delayed Delivery': 'orange',
  'Churn Risk': 'red',
  'Purchase Potential': 'green',
  'Order Inactivity': 'blue',
  'Upsell Opportunity': 'purple',
  'Purchase Frequency Drop': 'amber',
  'Low Digital Revenue Pct': 'teal',
  'Late Payments': 'pink',
  'Net New Customer': 'yellow',
  'Customer Journey': 'indigo',
};

const getBadgeColor = (label) => {
  return colorMapBackground[label] || '#FFFFFF'; // Fallback to white if not found
};

export function TabsTable({ tabledata }) {

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7; 
  
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = tabledata.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(tabledata.length / itemsPerPage);

  const isMobile = useMediaQuery('(max-width: 640px)');

  return (
    <>
    <div className={`overflow-x-hidden overflow-y-hidden w-full ${isMobile ? 'flex flex-col' : ''}`}>
    {isMobile ? (
        currentUsers?.map((user) => (
        <Link to={`/customer/${user.custcd}`} key={user.custcd}>
            <div className="p-4 border rounded-lg mb-4">
            <div className="font-semibold text-zinc-900 text-sm truncate w-full mb-4 dark:text-zinc-100">
                {user.custname}
            </div>
            <div className="flex flex-col gap-1 mb-2">
                {user.tags?.map((badge, index) => (
                <Badge key={index} className="bg-zinc-600 text-gray-50">
                    {badge}
                </Badge>
                ))}
            </div>
            <div className="flex flex-col gap-1">
                {user.insight_pills?.map((badge, index) => (
                <Badge key={index} color={getBadgeColor(badge)}>
                    {badge}
                </Badge>
                ))}
            </div>
            </div>
        </Link>
        ))
    ) : (
        <Table className="min-w-full mt-4">
        <TableCaption></TableCaption>
        <TableHeader>
            <TableRow>
            <TableHead className="w-1/3">Customer</TableHead>
            <TableHead>Customer Details</TableHead>
            <TableHead>Insights</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {currentUsers?.map((user) => (
            <TableRow key={user.custcd}>
                <TableCell className="w-1/3 font-semibold text-zinc-900 dark:text-zinc-100">
                    <Link to={`/customer/${user.custcd}`}>
                        {user.custname}
                    </Link>
                </TableCell>
                <TableCell className="w-1/3 text-zinc-500">
                    <div className="flex flex-col gap-1">
                        {user.tags?.map((badge, index) => (
                        <Badge key={index} className="min-w-[200px] max-w-[400px] bg-zinc-600 text-gray-50">
                            {badge}
                        </Badge>
                        ))}
                    </div>
                </TableCell>
                <TableCell className="w-1/3 text-zinc-500">
                    <div className="flex flex-col gap-1">
                        {user.insight_pills?.map((badge, index) => (
                        <Badge key={index} color={getBadgeColor(badge)} className="min-w-[200px] max-w-[400px]">
                            {badge}
                        </Badge>
                        ))}
                    </div>
                </TableCell>
            </TableRow>
            ))}
        </TableBody>
        <TableFooter>
        </TableFooter>
        </Table>
    )}
    </div>
    <Pagination className="mt-6 w-full flex justify-center">
    <PaginationPrevious 
        onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))} 
        disabled={currentPage === 1} 
    />
    <PaginationContent className="flex justify-center w-full">
        {createPaginationItems(totalPages, currentPage, setCurrentPage)}
    </PaginationContent>
    <PaginationNext 
        onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages))} 
        disabled={currentPage === totalPages} 
    />
    </Pagination>
    </>
  );
}

// useMediaQuery hook
function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  React.useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}