import React, { useState, useContext } from 'react';
import { cn } from 'utils/utils';
import { ScrollArea } from 'components/ui/scroll-area';
import ResponsiveModal from './components/responsive-modal';
import { ReminderCreate } from './reminder-create';
import { CardPopUp } from './card-popup';
import { ReminderCard } from './reminder-card';
import { AppContext } from 'contexts/AppContext';

export function RemindersList({ items, scrollHeight = 670 }) {
  const { isMobile } = useContext(AppContext);

  const [isEditing, setIsEditing] = useState(false);

  if (!items) {
    return <div>No reminders to display</div>;
  }

  const sortedItems = items.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleModifyOff = () => {
    setIsEditing(false);
  };

  return (
    <ScrollArea style={{ height: `${scrollHeight}px` }} className="overflow-y-auto">
      <div className="flex flex-col gap-2 pt-0">
        {sortedItems.map((item) => (
          <ResponsiveModal
            key={item.remindersid}
            handleModifyOff={handleModifyOff}
            trigger={
              <div role="button" tabIndex={0}>
                <ReminderCard item={item} />
              </div>
            }
            title={!isEditing ? item.header : 'Modify Reminder'}
            description={
              <>
                {!isEditing ? (
                  <>
                    <CardPopUp item={item} handleEditClick={handleEditClick} />
                  </>
                ) : (
                  <ReminderCreate selectedNote={item} handleClose={handleModifyOff} />
                )}
              </>
            }
          />
        ))}
      </div>
    </ScrollArea>
  );
}