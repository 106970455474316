import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AppContext } from 'contexts/AppContext';

export const NotesContext = createContext();

export const NotesProvider = ({ children }) => {
  const { salesAgentCD, custcd, BASE_URL, isAdmin, oktaId, userFirstName, userLastName, token } = useContext(AppContext);

  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAllNotes = async (custcd) => {
    if (!salesAgentCD) return
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-all-notes`, {
        params: { salesagentcd: salesAgentCD, custcd: custcd || 'none', oktaid: oktaId  },
        headers: { Authorization: `Bearer ${token}`}
      });
      setNotes(response.data);
      setLoading(false);
    } catch (err) {
      setNotes([]);
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllNotes('none')
  }, [salesAgentCD]);

  const createNote = async (note) => {
    //if (isAdmin) 

    if (isAdmin) {
      const fullName = `${userFirstName} ${userLastName}`;
      note.tags = [fullName];
    }
    try {
      const response = await axios.post(`${BASE_URL}/create-note`, {...note, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const updateNote = async (note) => {
    //if (isAdmin) return
    try {
      const response = await axios.post(`${BASE_URL}/update-note`,  {...note, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const bookmarkNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/bookmark-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const removeBookmarkNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/remove-bookmark-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const prioritizeNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/prioritize-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const removePrioritizeNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/remove-prioritize-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const archiveNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/archive-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  const unarchiveNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/unarchive-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none'); 
    } catch (err) {
      setError(err);
    }
  };

  const draftNote = async (note) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/draft-note`, {...note, oktaid: oktaId}, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none'); 
    } catch (err) {
      setError(err);
    }
  };

  const deleteNote = async (notesid) => {
    //if (isAdmin) return
    try {
      await axios.post(`${BASE_URL}/delete-note`, { notesid, oktaid: oktaId }, {headers: { Authorization: `Bearer ${token}`}});
      fetchAllNotes('none')
    } catch (err) {
      setError(err);
    }
  };

  return (
    <NotesContext.Provider value={{
      notes,
      loading,
      error,
      fetchAllNotes,
      createNote,
      updateNote,
      bookmarkNote,
      removeBookmarkNote,
      prioritizeNote,
      removePrioritizeNote,
      archiveNote,
      unarchiveNote,
      draftNote,
      deleteNote
    }}>
      {children}
    </NotesContext.Provider>
  );
};
