import React, { useState, useContext } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ChatContext } from "contexts/ChatContext";
import "../styles/Heading.css";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Box, Typography } from '@mui/material';
import logo_mini from 'img/logo_mini.png';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import { Heading } from 'components/catalyst/heading';
import { Sailboat } from 'lucide-react';

export function TopHeading({toggle}) {
  const { handleClearState } = useContext(ChatContext);
  const theme = useTheme();

  return (
    <>
      <div className="vsa-top-container">
        <div className="vsa-nav1">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={logo_mini} alt="Instalily" width="20" style={{ marginRight: '8px' }} />
            <Heading className="cosailor-heading">CoSailor</Heading>
          </Box>        
        </div>
        <div className="vsa-nav2">
          <div role="vsa-button" tabIndex={0} onClick={handleClearState} className="vsa-del-button" >
            <DeleteSweepIcon style={{ fontSize: 'inherit', width: 'inherit', height: 'inherit' }} />
          </div>
          <IconButton shape="rounded" size="small" onClick={toggle} sx={{ color: '#232323' }}>
            <CloseCircleOutlined style={{ fontSize: '1.15rem' }} />
          </IconButton>
        </div>
      </div>
    </>
  );
}
