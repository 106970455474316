import React, { useEffect, useState, useContext } from 'react';
import { z } from "zod";
import ColumnsComponent from "./components/columns";
import DataTable from "./components/data-table";
import { DataContext } from 'contexts/DataContext';

export default function CustomersPage() {
    const { customerReportData, setCustomerReportData } = useContext(DataContext);
    const columns = ColumnsComponent();

    return (
      <>
        <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10 pt-4">
          <DataTable data={customerReportData} columns={columns} />
        </div>
      </>
    );
}