import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

let mediaRecorder = null;
let audioBlobs = [];
let capturedStream = null;

export async function con() {
  try {
    await register(await connect());
  } catch (error) {
    console.error('Error during connection:', error);
  }
}

// Starts recording audio
export async function startRecording() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
      }
    });

    audioBlobs = [];
    capturedStream = stream;

    // Use the extended MediaRecorder library
    mediaRecorder = new MediaRecorder(stream, {
      mimeType: 'audio/wav'
    });

    // Add audio blobs while recording 
    mediaRecorder.addEventListener('dataavailable', event => {
      audioBlobs.push(event.data);
    });

    mediaRecorder.start();
  } catch (error) {
    console.error('Error during startRecording:', error);
  }
}

export async function stopRecording() {
  return new Promise(resolve => {
    try {
      if (!mediaRecorder) {
        resolve(null);
        console.log('No media recorder');
        return;
      }

      mediaRecorder.addEventListener('stop', () => {
        try {
          const mimeType = mediaRecorder.mimeType;
          const audioBlob = new Blob(audioBlobs, { type: mimeType });

          if (capturedStream) {
            capturedStream.getTracks().forEach(track => track.stop());
          }

          resolve(audioBlob);
        } catch (error) {
          console.error('Error while processing audioBlob:', error);
          resolve(null);
        }
      });

      mediaRecorder.stop();
    } catch (error) {
      console.error('Error during stopRecording:', error);
      resolve(null);
    }
  });
}

export function playAudio(audioBlob) {
  try {
    if (audioBlob) {
      const audio = new Audio();
      audio.src = URL.createObjectURL(audioBlob);
      audio.play();
    } else {
      console.error('No audio blob available to play');
    }
  } catch (error) {
    console.error('Error during playAudio:', error);
  }
}
