export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiJnRXZwSWFxb1ZjNjhCYjFIZVRPTlRlRXdLYW5VWmNVbmtoZW1CaWJJSGtvIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVyOHV3Mmc3bUFXbXE3RDJwNyIsIm5hbWUiOiJDcmlzdGluIENvbm5lcm5leSIsImVtYWlsIjoiQ3Jpc3Rpbi5Db25uZXJuZXlAc3JzZGlzdHJpYnV0aW9uLmNvbSIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9zcnMub2t0YS5jb20vb2F1dGgyL2F1c3Zvamd2OXh2ekNTRGlYMnA3IiwiYXVkIjoiMG9hdjZ5YWQwN1Jrc2U3NVoycDciLCJpYXQiOjE3MzEzNTE2NzQsImV4cCI6MTczMTM1NTI3NCwianRpIjoiSUQuV0ZnaTVFRU5Ud053R093OU03NE01Ui1qekFGNUFZME9PSFZXclRoV1Z2QSIsImFtciI6WyJzbXMiLCJtZmEiLCJtY2EiLCJwd2QiXSwiaWRwIjoiMDBvMTZ6andlY0NGdTdwVWoycDciLCJub25jZSI6ImZmUDhPNmVnaWZwbHM0Q2d1RE1TUW5vVVhGdmRwMzVUV29CcE9GZmRaclNOR1NTUktKTnRRRGV5bkU5VWtEdEciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJDQzEwNjQ3MkBtbWhmZ2IuY29tIiwiYXV0aF90aW1lIjoxNzMxMzQ0Mjc4LCJhdF9oYXNoIjoiWWFCZjhzdGNtSGRhUmJHSmEwZG92USJ9.pCyiRGp45nqLDvaym6Bh2tuQLpTGj7p8ips5KkjyBBFVRJt0ruvkEZvsSZLbcqKk8wetvcQ5A_a4wr5qqHFGSSrY7NKLdpI5paHIgMjq9q3wzh5OISNzOJGGG6tWON-Av4k_680K1upUwi9_KJp_RbdwwJHNFcnnTK9YIi4aLCgpehycoFB-gIdgmKIqjmQOwC4gU1f8zKTv7_auQ1UjozcauQiiSFYBF5bYPoVGnARHfmz5xslRFKgthekSW7eTJ728FM-wwdZxKahKnLM5dMW_QcusL0q97MTXo2vY7HSKEZiUJ9AWRpaMGYtzaENiIoM-p8EK11V66fAoIKLj1g"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
