import React, { useContext, useState } from 'react';
import { PlusIcon } from 'lucide-react';
import { Text } from 'components/catalyst/text';
import { Button } from 'components/ui/button';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import ResponsiveModalNotes from 'components/notes-component/components/responsive-modal-notes';
import { NotesList } from 'components/notes-component/notes-list';
import { NoteCreate } from 'components/notes-component/note-create';
import { NotesContext } from 'contexts/NotesContext';
import { DataContext } from 'contexts/DataContext';
import { useLocation } from 'react-router-dom';

export function Notes() {
  const location = useLocation();
  const { customerInsights, custcd } = useContext(DataContext);
  const { notes } = useContext(NotesContext);

  const customerPathMatch = location.pathname.match(/^\/customer\/(.+)/);
  const customerId = customerPathMatch ? customerPathMatch[1] : null;

  const safeNotes = Array.isArray(notes) ? notes : [];

  const filteredByAccount = safeNotes.filter(note =>
    customerId ? note.custcd === customerId : true
  );

  const filteredActive = filteredByAccount.filter(note => note.status === 'created' || note.status === 'updated');

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="mt-4">
      <Card>
        <CardHeader>
          <h2 className="text-lg font-semibold">Notes</h2>
        </CardHeader>
        <CardContent className="space-y-4">
          {filteredActive.length === 0 ? (
            <Text>There are no notes.</Text>
          ) : (
            <NotesList items={filteredActive} scrollHeight={326} />
          )}
        </CardContent>
        <CardFooter>
          <ResponsiveModalNotes
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleClose={handleClose}
            trigger={
              <Button variant="outline" className="mb-2 w-full" onClick={() => setIsOpen(true)}>
                <PlusIcon className="h-4 w-4 mr-2" />
                Create New Note
              </Button>
            }
            title="Create a New Note"
            description={<NoteCreate customerId={customerId} handleClose={handleClose} />}
          />
        </CardFooter>
      </Card>
    </div>
  );
}