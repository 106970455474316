import React, { useContext, useEffect } from 'react';
import { cn } from 'utils/utils';
import { Badge } from 'components/ui/badge';
import { ScrollArea } from 'components/ui/scroll-area';
import { BookmarkFilledIcon } from '@radix-ui/react-icons';
import { Archive, Building2Icon, BookmarkIcon, NotebookPenIcon, CheckCircle2, Trash2Icon } from 'lucide-react';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import { Text } from 'components/catalyst/text';
import { AppContext } from 'contexts/AppContext';
import { ModifyDropdown } from './components/modify-dropdown';
import { Link } from 'react-router-dom';
import { RemindersContext } from 'contexts/RemindersContext';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';
import { Button } from 'components/ui/button';
import { DialogClose } from 'components/ui/dialog';
import { toast } from 'sonner';

function getBadgeVariantFromLabel(label) {
  if (['voice', 'text'].includes(label.toLowerCase())) {
    return 'default';
  }

  if (['personal'].includes(label.toLowerCase())) {
    return 'outline';
  }

  return 'outline';
}

export function CardPopUp({ item, handleEditClick }) {
  const { isMobile, logEvent } = useContext(AppContext);
  const { markReminderViewed, markReminderCompleted } = useContext(RemindersContext);

  useEffect(() => {
    if (!item.viewed) {
      console.log('REMINDER VIEWED', item.remindersid);
      markReminderViewed(item.remindersid);
      logEvent('Reminders', 'Reminder Viewed', item.remindersid)
        .catch(error => console.error('Failed to log event:', error));
    }
  }, []);

  const updatedDate = new Date().toISOString();

  return (
    <div className="flex h-full flex-col p-4 sm:p-0">
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            {(item.custname || item.custcd) && (
              <div className="text-xs font-medium">
                <div className="flex items-center text-s">
                  <Building2Icon className="w-4 mr-2" />
                  <Link to={`/customer/${item.custcd}`}>{item.custname || item.custcd}</Link>
                </div>
              </div>
            )}
            {item.date_completed && <CheckCircle2 className="mr-1 h-4 w-4 text-green-500" />}
            {item.is_priority && <ExclamationCircleIcon className="w-4 text-red-600" />}
            {item.is_bookmark && <BookmarkFilledIcon className="ml-auto text-blue-500" />}
          </div>
          <div className={cn('ml-auto text-xs', item.selected ? 'text-foreground' : 'text-muted-foreground')}>
            {/* Ensure no <div> inside <p> */}
          </div>
        </div>
        
      </div>
      <div className="py-3">
        <div>{item.description}</div>
      </div>
      
      <div className="grid grid-cols-2">
        <div>
          {item.tags && item.tags.length ? (
            <div className="flex items-center gap-2 mt-2">
              {item.tags.map((label) => (
                <Badge key={label} variant={getBadgeVariantFromLabel(label)}>
                  {label}
                </Badge>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center justify-center w-full mt-2">
        <DialogClose asChild>
          <Button
            variant="solid"
            className="bg-black text-white w-full"
            onClick={() => {
              markReminderCompleted(item.remindersid, updatedDate);
              toast("Reminder has been marked as completed", {
                action: {
                  label: "Close",
                  onClick: () => console.log("Close"),
                },
              });
            }}
          >
            Mark as Completed
          </Button>
        </DialogClose>
      </div>
      <ModifyDropdown item={item} onEditClick={handleEditClick} />
    </div>
  );
}