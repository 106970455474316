import React, { useState, useEffect, useContext } from "react";
import { Area, AreaChart, CartesianGrid, XAxis, Tooltip, YAxis } from "recharts";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { ChartContainer } from "components/ui/chart";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { DataContext } from 'contexts/DataContext';
import { AppContext } from 'contexts/AppContext';
import { formatValue } from 'utils/formatValue';
import { startOfWeek, format, addWeeks, isBefore, subMonths } from 'date-fns';

export function CustomerSalesTable() {
  const { customerDailySales } = useContext(DataContext);
  const { logEvent } = useContext(AppContext);

  const [timeRange, setTimeRange] = useState("12m");
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const aggregateWeeklySales = () => {
      const weeklySales = {};
      const now = new Date();
      let startDate;

      // Set the start date based on the selected time range
      switch (timeRange) {
        case "6m":
          startDate = subMonths(now, 6);
          break;
        case "12m":
          startDate = subMonths(now, 12);
          break;
        case "24m":
          startDate = subMonths(now, 24);
          break;
        case "36m":
          startDate = subMonths(now, 36);
          break;
        default:
          startDate = subMonths(now, 3);
          break;
      }

      // Calculate the start of the first week
      let currentWeek = startOfWeek(startDate, { weekStartsOn: 0 }); // Week starts on Sunday

      // Initialize all weeks in the range with 0 sales and items
      while (isBefore(currentWeek, now)) {
        const weekStartStr = format(currentWeek, 'yyyy-MM-dd');
        weeklySales[weekStartStr] = { date: weekStartStr, sales: 0, num_items: 0 };
        currentWeek = addWeeks(currentWeek, 1); // Move to the next week
      }

      // Populate the weekly sales data
      customerDailySales.forEach((sale) => {
        const saleDate = new Date(sale.invoicedt);
        if (isBefore(saleDate, startDate) || isBefore(now, saleDate)) return;

        const saleWeekStart = startOfWeek(saleDate, { weekStartsOn: 0 });
        const saleWeekStartStr = format(saleWeekStart, 'yyyy-MM-dd');

        if (weeklySales[saleWeekStartStr]) {
          weeklySales[saleWeekStartStr].sales += sale.total_sales;
          weeklySales[saleWeekStartStr].num_items += sale.items.num_items;
        } else {
          weeklySales[saleWeekStartStr] = {
            date: saleWeekStartStr,
            sales: sale.total_sales,
            num_items: sale.items.num_items,
          };
        }
      });

      const aggregatedData = Object.values(weeklySales).sort((a, b) => new Date(a.date) - new Date(b.date));
      setChartData(aggregatedData);
      console.log("customerDailySales",customerDailySales)
      console.log("aggregatedData",aggregatedData)
    };

    aggregateWeeklySales();
  }, [customerDailySales, timeRange]);

  const handleTimeRangeChange = (value) => {
    setTimeRange(value);
    logEvent('Customer Sales Graph', 'Time Range Changed', { "time_range": value }); // Log event on change
  };

  return (
    <Card className="mt-4 sm:mt-0">
      <CardHeader className="flex gap-2 space-y-0 border-b sm:flex-row">
        <div className="grid flex-1 gap-1 sm:text-left">
          <CardTitle>
            <h2 className="text-lg font-semibold">Customer Sales</h2>
          </CardTitle>
          <CardDescription>
            Weekly Sales Data
          </CardDescription>
        </div>
        <Select value={timeRange} onValueChange={handleTimeRangeChange}>
          <SelectTrigger
            className="w-[160px] rounded-lg sm:ml-auto"
            aria-label="Select a value"
          >
            <SelectValue placeholder="Last 12 months" />
          </SelectTrigger>
          <SelectContent className="rounded-xl">
            <SelectItem value="90d" className="rounded-lg">
              Last 3 months
            </SelectItem>
            <SelectItem value="6m" className="rounded-lg">
              Last 6 months
            </SelectItem>
            <SelectItem value="12m" className="rounded-lg">
              Last 12 months
            </SelectItem>
            <SelectItem value="24m" className="rounded-lg">
              Last 24 months
            </SelectItem>
            <SelectItem value="36m" className="rounded-lg">
              Last 36 months
            </SelectItem>
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent className="px-2 pt-4 sm:px-6 sm:pt-6">
        <ChartContainer
          config={{
            sales: {
              label: "Sales",
            },
          }}
          className="aspect-auto h-[250px] w-full"
        >
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="fillsales" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor="hsl(var(--chart-1))"
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor="hsl(var(--chart-1))"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              minTickGap={32}
              interval={timeRange === "36m" || timeRange === "24m" ? 1 : 0}
              tickFormatter={(value) => format(new Date(value), "MMM")}
              tick={({ x, y, payload }) => {
                const date = new Date(payload.value);
                const monthYear = format(date, "MMM yyyy");
                const previousMonthYear = payload.index > 0 ? format(new Date(chartData[payload.index - 1].date), "MMM yyyy") : null;

                if (monthYear !== previousMonthYear) {
                  let label = format(date, "MMM");
                  if (timeRange === "24m" || timeRange === "36m") {
                    const month = date.getMonth();
                    if (timeRange === "36m") {
                      // Show labels for every third month in 36-month view
                      if (month % 3 === 0) {
                        label = format(date, "MMM yy");
                      } else {
                        return null;
                      }
                    } else {
                      // Show labels for every other month in 24-month view
                      if (month % 2 === 0) {
                        label = format(date, "MMM yy");
                      } else {
                        return null;
                      }
                    }
                  }
                  return (
                    <text 
                      x={x} 
                      y={y + 16} 
                      textAnchor="middle" 
                      className="recharts-cartesian-axis-tick-value" 
                      fontSize={timeRange === "24m" || timeRange === "36m" ? 8 : 10}
                    >
                      {label}
                    </text>
                  );
                }
                return null;
              }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              width={80}
              tickFormatter={(value) => formatValue(value)}
              label={{
                value: "Sales",
                angle: -90,
                position: "insideLeft",
                offset: 10,
                style: { textAnchor: "middle" }
              }}
            />
            <Tooltip
              content={({ payload, label }) => {
                if (payload && payload.length) {
                  const data = payload[0].payload;
                  return (
                    <div className="bg-white p-3 border border-gray-300 rounded-lg shadow-md text-sm">
                      <p className="font-bold text-base mb-1">
                        {format(new Date(label), "MMM d")}
                      </p>
                      <p className="text-gray-600">{`Sales: ${formatValue(data.sales)}`}</p>
                      <p className="text-gray-600">{`Number of Items: ${data.num_items}`}</p>
                    </div>
                  );
                }
                return null;
              }}
            />
            <Area
              dataKey="sales"
              type="linear"
              fill="url(#fillsales)"
              stroke="hsl(var(--chart-1))"
              stackId="a"
              animationDuration={500}
            />
          </AreaChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
