import React, { useState, useContext } from 'react';
import { Card, CardHeader, CardDescription, CardTitle } from 'components/ui/card';
import { Lightbulb, AlarmCheckIcon, CalendarIcon, DollarSignIcon } from 'lucide-react';
import { DataContext } from 'contexts/DataContext';
import { RemindersContext } from 'contexts/RemindersContext';
import { formatValue } from 'utils/formatValue'

export function NotifyCards() {
  const { sales30, salesYear, aggregateInsights } = useContext(DataContext);
  const { reminders } = useContext(RemindersContext);

  const formatValue = (value) => {
    if (!value) return '\u00A0';
    if (value >= 1000 && value < 1000000) return `$${(value / 1000).toFixed(1)}K`;
    if (value >= 1000000) return `$${(value / 1000000).toFixed(1)}M`;
    return `$${Math.floor(value).toLocaleString()}`;
  };

  const countTotalCustomers = (insights) => {
    return insights.reduce((total, insight) => {
      if (insight.customers) {
        return total + insight.customers.length;
      }
      return total;
    }, 0);
  };

  const totalCustomers = countTotalCustomers(aggregateInsights);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
      <Card className="col-span-1">
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#FFF0C4] rounded-full hidden sm:flex">
            <Lightbulb className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{totalCustomers || '\u00A0'}</CardTitle>
            <CardDescription>New Insights</CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card className="col-span-1">
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#FCCECA] rounded-full hidden sm:flex">
            <AlarmCheckIcon className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{Array.isArray(reminders) ? reminders.length : '\u00A0'}</CardTitle>
            <CardDescription>New Reminders</CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card className="col-span-1">
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#F1EAFA] rounded-full hidden sm:flex">
            <CalendarIcon className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{formatValue(sales30)}</CardTitle>
            <CardDescription>30 Day Sales</CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card className="col-span-1">
        <CardHeader className="flex items-center gap-1">
          <div className="flex items-center justify-center w-10 h-10 bg-[#CCF8DA] rounded-full hidden sm:flex">
            <DollarSignIcon className="w-6 h-6 text-black" />
          </div>
          <div className="text-center space-y-1">
            <CardTitle>{formatValue(salesYear)}</CardTitle>
            <CardDescription>YTD Sales</CardDescription>
          </div>
        </CardHeader>
      </Card>
    </div>
  );
}