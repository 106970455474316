import React, { useState, useEffect, useRef, useContext, forwardRef } from "react";
import "../styles/Input.css";

import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";
import SendIcon from "@mui/icons-material/Send";
import { ChatContext } from "contexts/ChatContext";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

const CustomInput = forwardRef((props, ref) => (
  <input ref={ref} {...props} type="custom" style={{ fontSize: '16px' }}/>
));

function Input() {
  const { salesAgentCD, currentCustCD, chatInstance, messages, setMessages,
     setTypingIndicator, setGhostMessage, handleSend, isMobile, handleClearState,
     chatMode, currentCustomer } = useContext(ChatContext);
  
  const [input, setInput] = useState("");
  
  return (
      <div id="custom-query-area" className="vsa-custom-query-area">
        {isMobile &&
          <div role="vsa-button" tabIndex={0} onClick={handleClearState} className="vsa-del-button-mobile" >
            <DeleteSweepIcon style={{ fontSize: 'inherit', width: 'inherit', height: 'inherit' }} />
          </div>
        }
        <OutlinedInput
            inputComponent={CustomInput}
            size="large"
            style={{ width: '100%',height:'50px', borderRadius: '14px', marginRight: '6px' }} 
            autoComplete='off'
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                    handleSend(input);
                    setInput("")
                    e.preventDefault();
                }
            }}
            placeholder="Ask a question..."
            id="custom-query"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: 'var(--border-color)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderWidth: '2px',
                borderColor: 'var(--input-border-color)', 
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'var(--input-border-color)', 
              },
              '&:hover, &.Mui-focused': {
                backgroundColor: 'var(--input-background-color)', 
              },
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
              '.MuiInputBase-input': {
                fontSize: '16px',
                color: 'var(--text-color-main)', 
                '&.Mui-focused': {
                  backgroundColor: 'var(--text-color-light)', 
                }
              }
            }}
            endAdornment={
            <InputAdornment position="end">
              <IconButton
                style={{ borderRadius: "50%" }}
                sx={{
                  borderRadius: '50%', 
                  '&:hover': {
                    background: 'rgba(0, 0, 0, 0.04)',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.35rem', 
                  },
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.background = "rgba(0, 0, 0, 0.04)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.background = "transparent")
                }
                onClick={async () => {
                  await handleSend(input);
                  setInput("");
                }}
              >
                <SendIcon style={{ color: "var(--text-color-main)" }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
  );
}

export default Input;
