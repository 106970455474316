"use client"

import { useState } from 'react'
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Card, CardContent } from "components/ui/card"
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar"
import { Badge } from "components/ui/badge"
import { ArrowUpCircle, MessageSquare, MoreHorizontal, Bookmark, ChevronLeft, ChevronRight, Search } from "lucide-react"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { Link } from 'react-router-dom'

const POSTS_PER_PAGE = 5

const savedPosts = [
  {
    id: 1,
    content: "What's your go-to strategy for upselling premium roofing materials to hesitant customers?",
    author: "User 54321",
    time: "2d ago",
    votes: 42,
    comments: 8,
    topics: ["Sales", "Product"]
  },
  {
    id: 2,
    content: "Just heard about a new eco-friendly shingle that's supposed to last 50 years. Anyone have experience with it?",
    author: "User 67890",
    time: "5d ago",
    votes: 38,
    comments: 12,
    topics: ["Product", "Technical", "Market Trends"]
  },
  {
    id: 3,
    content: "Supply chain delays are killing us. How are you managing customer expectations during these times?",
    author: "User 13579",
    time: "1w ago",
    votes: 56,
    comments: 23,
    topics: ["Customer Service", "Supply Chain"]
  },
  {
    id: 4,
    content: "Looking for recommendations on the best project management software for roofing companies. Any suggestions?",
    author: "User 24680",
    time: "2w ago",
    votes: 15,
    comments: 7,
    topics: ["Technical", "Software"]
  },
  {
    id: 5,
    content: "Has anyone implemented a successful referral program? What incentives work best?",
    author: "User 11223",
    time: "3w ago",
    votes: 28,
    comments: 14,
    topics: ["Sales", "Customer Service"]
  },
  {
    id: 6,
    content: "Discussing the impact of recent tariffs on imported roofing materials. How are you adjusting your pricing?",
    author: "User 33445",
    time: "1m ago",
    votes: 37,
    comments: 19,
    topics: ["Market Trends", "Supply Chain"]
  },
]

export default function SavedPostsPage() {
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')

  const filteredPosts = savedPosts.filter(post =>
    post.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.topics.some(topic => topic.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  const totalPages = Math.ceil(filteredPosts.length / POSTS_PER_PAGE)
  const paginatedPosts = filteredPosts.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
    window.scrollTo(0, 0)
  }

  return (
    <div className="container max-w-2xl mx-auto px-4 py-8">
      <Link to="/water-cooler" className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6">
        <ChevronLeft className="mr-2 h-4 w-4" />
        Back to Water Cooler
      </Link>

      <h1 className="text-2xl font-bold mb-6">Saved Posts</h1>

      <div className="mb-6">
        <div className="relative">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
          <Input
            placeholder="Search saved posts..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-8"
          />
        </div>
      </div>

      <div className="space-y-4">
        {paginatedPosts.map((post) => (
          <Card key={post.id}>
            <CardContent className="p-4">
              <p className="text-sm mb-2">{post.content}</p>
              <div className="flex flex-wrap gap-2 mb-2">
                {post.topics.map(topic => (
                  <Badge key={topic} variant="secondary">{topic}</Badge>
                ))}
              </div>
              <div className="flex items-center justify-between text-sm text-gray-500">
                <div className="flex items-center space-x-2">
                  <Avatar className="h-6 w-6">
                    <AvatarFallback>{post.author[0]}</AvatarFallback>
                  </Avatar>
                  <span>{post.author}</span>
                  <span>·</span>
                  <span>{post.time}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Button variant="ghost" size="sm">
                    <ArrowUpCircle className="h-4 w-4 mr-1" />
                    {post.votes}
                  </Button>
                  <Button variant="ghost" size="sm">
                    <MessageSquare className="h-4 w-4 mr-1" />
                    {post.comments}
                  </Button>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="sm">
                        <MoreHorizontal className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem>
                        <Bookmark className="mr-2 h-4 w-4" />
                        <span>Unsave Post</span>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>Share</DropdownMenuItem>
                      <DropdownMenuItem>Report</DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center items-center space-x-2 mt-6">
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ChevronLeft className="h-4 w-4" />
            Previous
          </Button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? "default" : "outline"}
              size="sm"
              onClick={() => handlePageChange(page)}
            >
              {page}
            </Button>
          ))}
          <Button
            variant="outline"
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
            <ChevronRight className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  )
}