import React, { useState, useContext } from 'react';
import { Card, CardHeader, CardDescription, CardTitle, CardContent } from 'components/ui/card'
import { DataContext } from 'contexts/DataContext';
import { formatValue } from 'utils/formatValue'

export function KPIs() {
  const { customerProfilePage } = useContext(DataContext);
  
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
      <Card>
        <CardHeader className="flex items-center">
          <div className="text-center pt-1 space-y-1">
            <CardTitle className="my-1">{formatValue(customerProfilePage.sales_t365d_cy)}</CardTitle>
            <CardDescription>
              Sales
              <br />(T12M)
            </CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card>
        <CardHeader className="flex items-center">
          <div className="text-center pt-1 space-y-1">
            <CardTitle className="my-1">{formatValue(customerProfilePage.aov_so_t90d_cy)}</CardTitle>
            <CardDescription>
              AOV
              <br />(T3M)
            </CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card>
        <CardHeader className="flex items-center">
          <div className="text-center pt-1 space-y-2">
            <CardTitle className="my-1">{formatValue(customerProfilePage.new_cl)}</CardTitle>
            <CardDescription>
              Credit Limit
              <br/>
            </CardDescription>
          </div>
        </CardHeader>
      </Card>

      <Card>
        <CardHeader className="flex items-center">
          <div className="text-center pt-1 space-y-1">
            <CardTitle className="my-1">{customerProfilePage.days_since_last_invoice || '\u00A0'}</CardTitle>
            <CardDescription>
              Last Invoice
              <br />(Days)
            </CardDescription>
          </div>
        </CardHeader>
      </Card>

    </div>
  )
}