import React, { useState, useContext } from 'react';
import { NotesContext } from "contexts/NotesContext";
import addDays from "date-fns/addDays";
import { cn } from 'utils/utils';
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { Label } from "components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Switch } from "components/ui/switch";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";
import { Fieldset } from 'components/catalyst/fieldset';
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { ResetIcon } from '@radix-ui/react-icons';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from 'contexts/AppContext';

export function SetDate({ reminderDate, setReminderDate }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleReset = (e) => {
    e.preventDefault();
    setReminderDate(null);
  };

  const handleSelect = (date) => {
    setReminderDate(date);
    setIsOpen(false);
  };

  return (
    <div className="flex items-center flex-start mt-4">
      <p className="text-sm text-muted-foreground w-[130px]">Set Reminder Date</p>
      <div className="flex items-center space-x-2">
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] justify-start text-left font-normal",
                !reminderDate && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {reminderDate ? format(reminderDate, "PPP") : <span>Pick a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="flex w-auto flex-col space-y-2 p-2">
            <Select
              onValueChange={(value) => {
                handleSelect(addDays(new Date(), parseInt(value)));
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent position="popper">
                <SelectItem value="0">Today</SelectItem>
                <SelectItem value="1">Tomorrow</SelectItem>
                <SelectItem value="3">In 3 days</SelectItem>
                <SelectItem value="7">In a week</SelectItem>
              </SelectContent>
            </Select>
            <div className="rounded-md border">
              <Calendar mode="single" selected={reminderDate} onSelect={handleSelect} />
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}
