import React, { useContext } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent } from 'components/ui/card';
import { Progress } from 'components/ui/progress';
import { DataContext } from 'contexts/DataContext';

export function MinimumSpendPotential() {
  const { customerProfilePage, customerConfig } = useContext(DataContext);

  const minspendpotential_numerator = customerProfilePage.minspendpotential_numerator || 0;
  const minspendpotential_denominator = customerConfig.min_spend_potential || customerProfilePage.minspendpotential_denominator || 1;

  let percentage = (minspendpotential_numerator / minspendpotential_denominator) * 100;
  if (percentage > 100) {
    percentage = 100;
  }

  function formatNumber(value) {
    return value.toLocaleString();
  }
 
  return (
    <div className="mt-4">
      <Card>
        <CardHeader>
          <h2 className="text-lg font-semibold">Monthly Minimum Spend Potential</h2>
        </CardHeader>
        <CardContent>
          <Progress value={percentage} />
          <Text>
            ${formatNumber(minspendpotential_numerator)} / ${formatNumber(minspendpotential_denominator)}
          </Text>
        </CardContent>
      </Card>
    </div>
  );
}
