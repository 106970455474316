import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/ChatWindow.css";
import { ChatContext } from "contexts/ChatContext";
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { TextField, Button } from '@mui/material';

function Feedback({ message, messageId }) {
  const { handleFeedback, feedback, handleWrittenFeedback, feedbackOpen, setFeedbackOpen, writeFeedback,
    setWriteFeedback, switchFeedbackOpen } = useContext(ChatContext);
  return (
      <>
        <div className="vsa-feedback-icons">
          <div>
            {feedback[messageId] === true ? (
                <ThumbUpAltIcon style={{marginRight:'2px',color:'#808080', width:'18px'}} />
            ) : (
                <ThumbUpOffAltIcon style={{marginRight:'2px',color:'#808080',  width:'18px'}} onClick={() => handleFeedback( true , messageId)} />
            )}
            {feedback[messageId] === false ? (
                <ThumbDownAltIcon style={{color:'#808080', width:'18px'}} />
            ) : (
                <ThumbDownOffAltIcon style={{color:'#808080', width:'18px'}} onClick={() => handleFeedback( false , messageId)} />
            )}
          </div>
        </div>
        {feedbackOpen[messageId] == true && 
        <>
            <TextField
            multiline
            rows={3} 
            variant="outlined"
            placeholder="Enter your feedback..."
            style={{ width: '100%', overflow: 'auto' }} 
            value={writeFeedback}
            onChange={(e) => setWriteFeedback(e.target.value)}
            sx={{
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#808080', 
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#808080', 
                    borderWidth: '1px',
                  },
              }}
            InputProps={{
              sx: {
                fontSize:'14px',
                marginBottom:'3px'
              },
              style: {
                overflowY: 'auto',
              },
            }}
          />
        <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'flex-end', alignItems: "center" }}>
            <Button variant="contained" size="small" onClick={() => switchFeedbackOpen(false, messageId, true)}
                sx={{
                  '& .MuiButton-label': { 
                  fontSize: '14px',
                  }
                }}
                style={{fontSize: '14px', backgroundColor: '#367CB6', color: 'white', marginRight: '10px', textTransform: 'none' }}>
                Close
            </Button>
            <Button variant="contained" size="small" onClick={() => handleWrittenFeedback(messageId)}
                sx={{
                  '& .MuiButton-label': { 
                  fontSize: '14px',
                  }
                }}
                style={{fontSize: '14px', backgroundColor: '#367CB6', color: 'white', marginRight: '0px', textTransform: 'none' }}>
                Send
            </Button>
        </div>
        </>
        }
    </>
  );
}

export default Feedback;
