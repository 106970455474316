import React from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  CaretSortIcon,
} from "@radix-ui/react-icons";

import { cn } from "utils/utils";
import { Button } from "components/ui/button";

function DataTableColumnHeader({ sortConfig, requestSort, title, className, sortKey }) {
  const getSortIcon = () => {
    if (sortConfig.key !== sortKey) return <CaretSortIcon className="ml-2 h-4 w-4" />;
    if (sortConfig.direction === "ascending") return <ArrowUpIcon className="ml-2 h-4 w-4" />;
    if (sortConfig.direction === "descending") return <ArrowDownIcon className="ml-2 h-4 w-4" />;
    return <CaretSortIcon className="ml-2 h-4 w-4" />;
  };

  return (
    <div className={cn("flex items-center space-x-2", className)}>
      <Button
        variant="ghost"
        size="sm"
        className="-ml-3 h-8"
        onClick={() => requestSort(sortKey)}
      >
        <span>{title}</span>
        {getSortIcon()}
      </Button>
    </div>
  );
}

export default DataTableColumnHeader;
