import React, { useState, useEffect, useRef, useContext } from 'react'
import { Archive, Building2Icon, BookmarkIcon, NotebookPenIcon, Trash2Icon } from 'lucide-react'
import { ExclamationCircleIcon } from '@heroicons/react/16/solid'
import { Text } from 'components/catalyst/text'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'
import { RemindersContext } from 'contexts/RemindersContext';
import { DialogClose } from 'components/ui/dialog'; // Add this import
import { toast } from 'sonner'; // Add this import

export function ModifyDropdown({ item, onEditClick }) {
    const { deleteReminder, archiveReminder, unarchiveReminder } = useContext(RemindersContext);
  
    const handleArchiveClick = () => {
      if (item.status === 'archived') {
        unarchiveReminder(item.remindersid); // Unarchive if already archived
        toast("Reminder has been unarchived", {
          description: "Moved to All Reminders",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        });
      } else {
        archiveReminder(item.remindersid); // Archive if not archived
        toast("Reminder has been archived", {
          description: "Moved to Archive",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        });
      }
    };
  
    const handleDeleteClick = () => {
      deleteReminder(item.remindersid); // Delete note
      toast("Reminder has been deleted", {
        description: "Moved to Trash",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
    };
  
    return (
        <div className="mt-2">
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full">
                Modify Reminder
            </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[460px] mt-2">
            <DropdownMenuLabel>Reminder Options</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                  <DropdownMenuItem onSelect={() => onEditClick()}>
                      <NotebookPenIcon className="mr-2 h-4 w-4" />
                      <span>Edit Reminder</span>
                  </DropdownMenuItem>
                <DialogClose asChild>
                  <DropdownMenuItem onSelect={handleArchiveClick}>
                      <Archive className="mr-2 h-4 w-4" />
                      <span>{item.status === 'archived' ? 'Unarchive' : 'Archive'}</span> 
                  </DropdownMenuItem>
                </DialogClose>
                {item.status !== 'deleted' && (
                  <DropdownMenuItem onSelect={handleDeleteClick}>
                      <Trash2Icon className="mr-2 h-4 w-4" />
                      <span>Send to Trash</span>
                  </DropdownMenuItem>
                )}
            </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }