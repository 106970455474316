import React, { useContext, useMemo } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardDescription, CardTitle, CardContent, CardFooter } from 'components/ui/card';
import { Progress } from "components/ui/progress";
import { DataContext } from 'contexts/DataContext';

export function Breakdown() {
  const { insightPercent } = useContext(DataContext);

  const formatPercentage = (value) => {
    return value.toFixed(1);
  };

  // Sort insights by percentage in descending order
  const sortedInsights = useMemo(() => {
    return [...insightPercent].sort((a, b) => b.percentage - a.percentage);
  }, [insightPercent]);

  return (
    <div className="sm:mt-4">
      <Card className="h-full mb-4 sm:mb-0">
        <CardHeader>
          <h2 className="text-lg font-semibold">Customers Breakdown</h2>
        </CardHeader>
        <CardContent className="space-y-2">
          {sortedInsights.map((insight, index) => (
            <div key={index} className="my-2 flex items-center space-x-2">
              <div className="flex-1">
                <div className="mb-1 text-sm">{insight.insight_type}</div>
                <Progress value={insight.percentage} />
              </div>
              <div className="ml-2 text-sm">{formatPercentage(insight.percentage)}%</div>
            </div>
          ))}
        </CardContent>
        <CardFooter></CardFooter>
      </Card>
    </div>
  );
}
