import React, { useState, useEffect, useRef, useContext } from "react";
import '../styles/ProgressCircle.css'; 
import { ChatContext } from "contexts/ChatContext";

const ProgressCircle = () => {

    const { stopGenerating } = useContext(ChatContext);
      
    return (
        <div className="vsa-progress-input-area">
            <div className="vsa-progress-circle-container" onClick={stopGenerating}>
                <div className="vsa-progress-circle"></div>
                <div className="vsa-stop-square"></div> 
            </div>
        </div>
    );
};
  
export default ProgressCircle;