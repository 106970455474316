import React, { useState, useEffect, useRef, useContext } from 'react';
import { Archive, Building2Icon, BookmarkIcon, NotebookPenIcon, Trash2Icon } from 'lucide-react';
import { ExclamationCircleIcon } from '@heroicons/react/16/solid';
import { Text } from 'components/catalyst/text';
import { Button } from 'components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { NotesContext } from 'contexts/NotesContext';
import { toast } from 'sonner'; // Add this import

export function ModifyDropdown({ item, onEditClick }) {
    const { deleteNote, archiveNote, unarchiveNote, removeBookmarkNote, bookmarkNote } = useContext(NotesContext);
  
    const handleArchiveClick = () => {
      if (item.status === 'archived') {
        unarchiveNote(item.notesid);
        toast("Note has been unarchived", {
          description: "Moved to All Notes",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        });
      } else {
        archiveNote(item.notesid);
        toast("Note has been archived", {
          description: "Moved to Archive",
          action: {
            label: "Close",
            onClick: () => console.log("Close"),
          },
        });
      }
    };
  
    const handleDeleteClick = () => {
      deleteNote(item.notesid);
      toast("Note has been deleted", {
        description: "Moved to Trash",
        action: {
          label: "Close",
          onClick: () => console.log("Close"),
        },
      });
    };
  
    return (
        <div className="mt-4">
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
            <Button variant="outline" className="w-full">
                Modify Note
            </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[460px] mt-2">
            <DropdownMenuLabel>Note Options</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
                <DropdownMenuItem onSelect={() => onEditClick()}>
                    <NotebookPenIcon className="mr-2 h-4 w-4" />
                    <span>Edit Note</span>
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={handleArchiveClick}>
                    <Archive className="mr-2 h-4 w-4" />
                    <span>{item.status === 'archived' ? 'Unarchive' : 'Archive'}</span> 
                </DropdownMenuItem>
                {item.status !== 'deleted' && (
                    <DropdownMenuItem onSelect={handleDeleteClick}>
                        <Trash2Icon className="mr-2 h-4 w-4" />
                        <span>Send to Trash</span>
                    </DropdownMenuItem>
                )}
            </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
}