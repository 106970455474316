"use client"

import * as React from "react"
import { useTheme } from "next-themes"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu"
import { LightbulbIcon } from "lucide-react"
import { SunMoon } from "lucide-react"

export function ModeToggle() {
  const { setTheme } = useTheme()

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button 
          className="hidden md:flex items-center gap-2 w-full py-2 text-sm font-medium text-left text-zinc-900 dark:text-white hover:bg-transparent focus:bg-transparent"
          onClick={(e) => e.preventDefault()}
        >
          <SunMoon className="h-5 w-5" />
          <span>Toggle Theme</span>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" side="right" sideOffset={5}>
        <DropdownMenuItem onClick={() => setTheme("light")}>
          Light
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => setTheme("dark")}>
          Dark
        </DropdownMenuItem>
        {/*
        <DropdownMenuItem onClick={() => setTheme("system")}>
          System
        </DropdownMenuItem>
        */}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}