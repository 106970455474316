import React, { useState, useEffect, useRef, useContext } from 'react'
import { cn } from 'utils/utils'
import { Badge } from 'components/ui/badge'
import { ScrollArea } from 'components/ui/scroll-area'
import ResponsiveModalNotes from './components/responsive-modal-notes'
import { NoteCreate } from './note-create'
import { AppContext } from 'contexts/AppContext';
import { LinkedContent } from './components/linked-content'
import { ModifyDropdown } from './components/modify-dropdown'
import { NoteCard } from'./note-card';
import { CardPopUp } from './card-popup'


export function NotesList({ items, scrollHeight = 670 }) {
  const { isMobile } = useContext(AppContext);

  const [openNoteId, setOpenNoteId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  if (!items) {
    return <div>No notes to display</div>;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleModifyOff = () => {
    setIsEditing(false);
  };

  const sortedItems = items
    .sort((a, b) => new Date(b.creation_date) - new Date(a.creation_date))
    .sort((a, b) => (b.is_bookmark ? 1 : -1) - (a.is_bookmark ? 1 : -1));

  return (
    <ScrollArea style={{ height: `${scrollHeight}px` }} className="overflow-y-auto">
      <div className="flex flex-col gap-2 pt-0">
        {sortedItems.map((item) => (
          <ResponsiveModalNotes
            key={item.notesid}
            handleModifyOff={handleModifyOff}
            trigger={
              <button onClick={() => setOpenNoteId(item.notesid)}>
                <NoteCard item={item} />
              </button>
            }
            title={!isEditing ? item.header : 'Modify Note'}
            description={
              <>
                {!isEditing ? (
                  <CardPopUp item={item} handleEditClick={handleEditClick} />
                ) : (
                  <NoteCreate selectedNote={item} handleClose={handleModifyOff} />
                )}
              </>
            }
          />
        ))}
      </div>
    </ScrollArea>
  );
}
