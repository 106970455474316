import { cn } from "utils/utils";
import { buttonVariants } from "components/ui/button";

export function Nav({ links, selectedLink, handleLinkSelection }) {
  return (
    <div className="flex flex-col gap-4 py-2">
      <div className="grid gap-1">
        {links.map((link, index) => (
          <button
            key={index} // Add this line
            onClick={() => handleLinkSelection(link.title, link.status)}
            className={cn(
              buttonVariants({ variant: "ghost", size: "sm" }),
              "flex justify-between items-center w-full", // Flexbox layout to control spacing
              selectedLink === link.title && "bg-black text-white" // Highlight selected link with black background
            )}
          >
            <div className="flex items-center">
              <link.icon className="mr-2 h-4 w-4" />
              {link.title}
            </div>
            {link.label && (
              <span>
                {link.label}
              </span>
            )}
          </button>
        ))}
      </div>
    </div>
  );
}

