import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'
import { Reminders } from 'components/reminders-component/reminders'

export default function RemindersPage() {
  return (
    <div className="border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap pt-4">
        <Card className="w-full">
          <CardHeader>
            <Reminders />
          </CardHeader>
          <CardFooter></CardFooter>
        </Card>
      </div>
    </div>
  )
}