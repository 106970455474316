import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);
  if (testing) return children

  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!="eyJraWQiOiJnRXZwSWFxb1ZjNjhCYjFIZVRPTlRlRXdLYW5VWmNVbmtoZW1CaWJJSGtvIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVyOHV3Mmc3bUFXbXE3RDJwNyIsIm5hbWUiOiJDcmlzdGluIENvbm5lcm5leSIsImVtYWlsIjoiQ3Jpc3Rpbi5Db25uZXJuZXlAc3JzZGlzdHJpYnV0aW9uLmNvbSIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9zcnMub2t0YS5jb20vb2F1dGgyL2F1c3Zvamd2OXh2ekNTRGlYMnA3IiwiYXVkIjoiMG9hdjZ5YWQwN1Jrc2U3NVoycDciLCJpYXQiOjE3MzEzNTE2NzQsImV4cCI6MTczMTM1NTI3NCwianRpIjoiSUQuV0ZnaTVFRU5Ud053R093OU03NE01Ui1qekFGNUFZME9PSFZXclRoV1Z2QSIsImFtciI6WyJzbXMiLCJtZmEiLCJtY2EiLCJwd2QiXSwiaWRwIjoiMDBvMTZ6andlY0NGdTdwVWoycDciLCJub25jZSI6ImZmUDhPNmVnaWZwbHM0Q2d1RE1TUW5vVVhGdmRwMzVUV29CcE9GZmRaclNOR1NTUktKTnRRRGV5bkU5VWtEdEciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJDQzEwNjQ3MkBtbWhmZ2IuY29tIiwiYXV0aF90aW1lIjoxNzMxMzQ0Mjc4LCJhdF9oYXNoIjoiWWFCZjhzdGNtSGRhUmJHSmEwZG92USJ9.pCyiRGp45nqLDvaym6Bh2tuQLpTGj7p8ips5KkjyBBFVRJt0ruvkEZvsSZLbcqKk8wetvcQ5A_a4wr5qqHFGSSrY7NKLdpI5paHIgMjq9q3wzh5OISNzOJGGG6tWON-Av4k_680K1upUwi9_KJp_RbdwwJHNFcnnTK9YIi4aLCgpehycoFB-gIdgmKIqjmQOwC4gU1f8zKTv7_auQ1UjozcauQiiSFYBF5bYPoVGnARHfmz5xslRFKgthekSW7eTJ728FM-wwdZxKahKnLM5dMW_QcusL0q97MTXo2vY7HSKEZiUJ9AWRpaMGYtzaENiIoM-p8EK11V66fAoIKLj1g")) {
    return children;
  }

  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!isAuthenticated && !isLoading && !currentUrl.includes('/login/callback')) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
