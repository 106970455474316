"use client";

import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Badge } from 'components/catalyst/badge';
import { Card, CardHeader, CardContent } from 'components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { Link } from 'react-router-dom';
import { TabsTable } from './TabsTable'
import { DataContext } from 'contexts/DataContext';
import moment from 'moment';
import { Input, InputGroup } from 'components/catalyst/input';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';
import { AppContext } from 'contexts/AppContext';

function filterAndRankNewCustomers(data) {

  return data
    .filter(customer => customer.tenureyears <= 1)
    .slice(0, 20).sort((a, b) => b.sales_t365d_cy - a.sales_t365d_cy)
}

function filterCustomers(data, searchTerm) {
  if (!searchTerm) return data;

  return data.filter(customer => {
    const searchTermLower = searchTerm.toLowerCase();
    const custnameMatch = customer.custname?.toLowerCase().includes(searchTermLower);
    const pillsMatch = customer.insight_pills?.some(pill => pill.toLowerCase().includes(searchTermLower));
    const tagsMatch = customer.tags?.some(tag => tag.toLowerCase().includes(searchTermLower));
    
    return custnameMatch || pillsMatch || tagsMatch;
  });
}

export function CustomerTable() {
  const { customerReportPriorityData, customerReportData } = useContext(DataContext);
  const { logEvent } = useContext(AppContext);

  const newCustomersData = customerReportData.filter(cust => 
    cust.net_new_ranking != null
  );

  const [currentTab, setCurrentTab] = useState('priority');
  const [searchInput, setSearchInput] = useState('');

  const [longestSearch, setLongestSearch] = useState('');
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    setSearchInput(newSearch);

    // Update longestSearch if the new search input is longer
    if (newSearch.length > longestSearch.length) {
      setLongestSearch(newSearch);
    }

    // Clear the existing debounce timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer for 5 seconds
    const newTimer = setTimeout(() => {
      // Log the longest search string after 5 seconds of inactivity
      if (longestSearch) {
        logEvent('Priority/New Customers', 'Search Input Logged', { "search": longestSearch });
        console.log("Logged Search:", longestSearch);

        // Reset longest search for the next period
        setLongestSearch('');
      }
    }, 5000);

    // Update the debounce timer
    setDebounceTimer(newTimer);
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [debounceTimer]);

  useEffect(() => {
    if (currentTab === "new") {
      logEvent("Priority/New Customer Table", "New Tab Selected", {});
    } else if (currentTab === "priority") {
      logEvent("Priority/New Customer Table", "Priority Tab Selected", {});
    }
  }, [currentTab]);

  const filteredPriorityData = useMemo(
    () => filterCustomers(customerReportPriorityData, searchInput).slice(0, 15), // Limit to top 15
    [customerReportPriorityData, searchInput]
  );

  const filteredNewCustomersData = useMemo(
    () => filterCustomers(newCustomersData, searchInput),
    [newCustomersData, searchInput]
  );

  const customerTypeText = currentTab === 'priority' ? 'Priority' : 'New';
  const customerCount = currentTab === 'priority' ? filteredPriorityData.length : filteredNewCustomersData.length;

  return (
    <Card className="w-full mt-4">
      <Tabs 
        defaultValue="priority" 
        className="w-full" 
        onValueChange={(value) => setCurrentTab(value)}
      >
        <CardHeader>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div className="mb-4 sm:mb-0">
            <h2 className="text-lg font-semibold">{customerTypeText} Customers</h2>
            <div className="text-sm mt-2">
              <strong>{customerCount}</strong> {customerTypeText.toLowerCase()} out of <strong>{customerReportData.length}</strong> total customers
            </div>
          </div>
          <TabsList className="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
            <TabsTrigger value="priority" className="text-zinc-600 dark:text-zinc-200">Priority Customers</TabsTrigger>
            <TabsTrigger value="new" className="text-zinc-600 dark:text-zinc-200">New Customers</TabsTrigger>
          </TabsList>
        </div>
        </CardHeader>
        <CardContent>
          <div className="mb-4">
            <InputGroup >
              <MagnifyingGlassIcon />
              <Input
                name="search"
                placeholder="Search&hellip;"
                aria-label="Search"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                  handleSearchChange(e);
                }}
              />
            </InputGroup>
          </div>
          <TabsContent value="priority" className="flex-1 overflow-y-auto m-0">
            <TabsTable tabledata={filteredPriorityData} />
          </TabsContent>
          <TabsContent value="new" className="flex-1 overflow-y-auto m-0">
            <TabsTable tabledata={filteredNewCustomersData} />
          </TabsContent>
        </CardContent>
      </Tabs>
    </Card>
  );
}
