"use client";
import React, { useState, useContext, useEffect } from "react";
import { AlertCircle, Archive, File, Inbox, Search, StickyNoteIcon, Trash2, PlusIcon, CheckCircle } from "lucide-react";
import { cn } from "utils/utils";
import { Input } from "components/ui/input";
import { Separator } from "components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { TooltipProvider } from "components/ui/tooltip";
import { AccountSwitcher } from "./components/account-switcher";
import { ReminderCreate } from "./reminder-create";
import { RemindersList } from "./reminders-list";
import { Nav } from "./components/nav";
import { Button } from "components/ui/button";
import ResponsiveModal from 'components/reminders-component/components/responsive-modal'
import { RemindersContext } from "contexts/RemindersContext";
import { DataContext } from "contexts/DataContext";
import { Text } from "components/catalyst/text";
import { AppContext } from "contexts/AppContext";

export function Reminders({ accounts }) {
  const { reminders } = useContext(RemindersContext);
  const { customerReportData } = useContext(DataContext);
  const { logEvent } = useContext(AppContext);

  const [selectedTab, setSelectedTab] = useState("all");

  useEffect(() => {
    // Log an event when the tab changes
    if (selectedTab === "user") {
      logEvent("Reminders", "User Tab Selected", {});
    } else if (selectedTab === "all") {
      logEvent("Reminders", "All Reminders Tab Selected", {});
    }
  }, [selectedTab]);

  const [searchInput, setSearchInput] = useState('');
  const [longestSearch, setLongestSearch] = useState('');
  const [debounceTimer, setDebounceTimer] = useState(null);

  const handleSearchChange = (e) => {
    const newSearch = e.target.value;
    setSearchInput(newSearch);

    // Update longestSearch if the new search input is longer
    if (newSearch.length > longestSearch.length) {
      setLongestSearch(newSearch);
    }

    // Clear the existing debounce timer
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    // Set a new debounce timer for 5 seconds
    const newTimer = setTimeout(() => {
      // Log the longest search string after 5 seconds of inactivity
      if (longestSearch) {
        logEvent('Reminders', 'Search Input Logged', { "search": longestSearch });
        console.log("Logged Search:", longestSearch);

        // Reset longest search for the next period
        setLongestSearch('');
      }
    }, 5000);

    // Update the debounce timer
    setDebounceTimer(newTimer);
  };

  useEffect(() => {
    // Clean up the timer on component unmount
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [debounceTimer]);

  const safeNotes = Array.isArray(reminders) ? reminders : [];

  const [selectedLink, setSelectedLink] = useState("All Reminders"); // Default selected link
  const [selectedAccount, setSelectedAccount] = useState('');
  const [filteredStatus, setFilteredStatus] = useState(null); 

  const filteredReminders = safeNotes
  .filter(reminder => (selectedAccount ? reminder.custcd === selectedAccount : true))
  .filter(reminder => {
    if (selectedLink === "All Reminders") {
      return reminder.status === "active" || reminder.status === "updated";
    }
    return filteredStatus ? reminder.status === filteredStatus : true;
  })
  .filter(reminder => {
    const searchTerm = searchInput.toLowerCase();
    return (
      reminder.header?.toLowerCase().includes(searchTerm) ||
      reminder.description?.toLowerCase().includes(searchTerm) ||
      reminder.custcd?.toLowerCase().includes(searchTerm) ||
      reminder.date_created?.toLowerCase().includes(searchTerm) ||
      reminder.date_due?.toLowerCase().includes(searchTerm) ||
      reminder.custname?.toLowerCase().includes(searchTerm)
    );
  });

  const draftCount = Array.isArray(reminders) ? reminders.filter(note => note.status === 'draft').length : 0;
  const archiveCount = Array.isArray(reminders) ? reminders.filter(note => note.status === 'archived').length : 0;
  const trashCount = Array.isArray(reminders) ? reminders.filter(note => note.status === 'deleted').length : 0;
  const notesCount = Array.isArray(reminders) ? reminders.filter(note => note.status === 'active' || note.status === "updated").length : 0;
  
  const handleLinkSelection = (link, status) => {
    setSelectedLink(link);
    setFilteredStatus(status);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false); // Use setIsOpen here
  };

  return (
    <TooltipProvider delayDuration={0}>
      <div className="h-full flex flex-col sm:flex-row overflow-hidden sm:space-x-6">
        <div className="sm:flex flex-col sm:w-1/4 pb-4 h-full">
          <div className={cn("flex flex-col items-stretch", "px-0")}>
            <ResponsiveModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleClose={handleClose}
              trigger={
                <Button variant="outline" className="mb-2 w-full" onClick={() => setIsOpen(true)}>
                  <PlusIcon className='h-4 w-4 mr-2' />
                  Create Reminder
                </Button>
              }
              title="Create a New Reminder"
              description={<ReminderCreate handleClose={handleClose} setIsOpen={setIsOpen} />}
            />
          </div>
          <Nav
            selectedLink={selectedLink}
            handleLinkSelection={handleLinkSelection} 
            links={[
              { title: "All Reminders", label: notesCount, icon: Inbox, status: null },
              { title: "Drafts", label: draftCount, icon: File, status: 'draft' },
              { title: "Archive", label: archiveCount, icon: Archive, status: 'archived' },
              { title: "Trash", label: trashCount, icon: Trash2, status: 'deleted' },
            ]}
          />
          <Separator />
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:w-3/4 h-full">
          <div className="flex-1 overflow-y-auto flex flex-col">
            <Tabs defaultValue="all" onValueChange={(value) => setSelectedTab(value)} className="flex-1 flex flex-col">
              <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <h1 className="text-xl font-bold mb-2 sm:mb-0">Reminders</h1>
                <TabsList className="w-full sm:w-auto sm:ml-auto mt-2 sm:mt-0">
                  <TabsTrigger value="all" className="text-zinc-600 dark:text-zinc-200">All Reminders</TabsTrigger>
                  <TabsTrigger value="user" className="text-zinc-600 dark:text-zinc-200">User Reminders</TabsTrigger> 
                </TabsList>
              </div>
              <div className="bg-background/95 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
              <form>
                <div className="relative">
                  <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input
                    placeholder="Search"
                    className="pl-8"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                      handleSearchChange(e);
                    }}
                  />
                </div>
              </form>
              </div>
              <TabsContent value="all" className="flex-1 overflow-y-auto m-0">
                {filteredReminders.length === 0 ? (
                  <Text>There are no reminders.</Text>
                ) : (
                  <RemindersList items={filteredReminders} />
                )}
              </TabsContent>
              <TabsContent value="user" className="flex-1 overflow-y-auto m-0">
                  {filteredReminders.length === 0 ? (
                    <Text>There are no reminders.</Text>
                  ) : (
                    <RemindersList items={filteredReminders ? filteredReminders?.filter((item) => item.system_generated==false) : []} />
                  )}
              </TabsContent> 
            </Tabs>
          </div>
        </div>
      </div>
    </TooltipProvider>
  );
}