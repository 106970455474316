import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'a95d29ba-b69e-401a-9b54-12bc717d2daa',
    clientToken: 'pubedd684b32af08ba7ec369925f4960e8d',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'cosailor',
    env: 'main',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});