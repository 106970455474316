import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';

const LoginCallback = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes('/login/callback')) {
      oktaAuth.handleLoginRedirect()
        .then(() => {
          navigate('/');
        })
        .catch(err => {
          console.error('Error handling login redirect:', err);
          navigate('/');
        });
    }
  }, [oktaAuth]);

  return <div>Loading...</div>;
};

export default LoginCallback;