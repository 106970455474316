"use client";

import { useState, useRef, useEffect } from 'react';
import { Play, Pause, X } from 'lucide-react';
import { Button } from "components/ui/button";
import { Progress } from "components/ui/progress";
import { useMediaQuery } from 'react-responsive';

export default function PodcastPlayer({ audioSrc, onClose }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  
  const isMobile = useMediaQuery({ maxWidth: 640 });

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = speed;
    }
  }, [speed]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play().then(() => {
        setIsPlaying(true);
        setIsVisible(true);
      }).catch(error => {
        console.error('Error attempting to play:', error);
      });
    }
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateProgress = () => {
        setProgress((audio.currentTime / audio.duration) * 100);
      };
      const updateDuration = () => {
        setDuration(audio.duration);
      };

      audio.addEventListener('timeupdate', updateProgress);
      audio.addEventListener('loadedmetadata', updateDuration);
      audio.addEventListener('durationchange', updateDuration);

      return () => {
        audio.removeEventListener('timeupdate', updateProgress);
        audio.removeEventListener('loadedmetadata', updateDuration);
        audio.removeEventListener('durationchange', updateDuration);
      };
    }
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleEnded = () => setIsPlaying(false);
      audio.addEventListener('ended', handleEnded);
      return () => {
        audio.removeEventListener('ended', handleEnded);
      };
    }
  }, []);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const changeSpeed = (newSpeed) => {
    setSpeed(newSpeed);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleProgressClick = (e) => {
    const progressBar = e.currentTarget;
    const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
    const newProgress = (clickPosition / progressBar.offsetWidth) * 100;
    if (audioRef.current) {
      audioRef.current.currentTime = (newProgress / 100) * duration;
    }
  };

  return (
    <div 
      className={`z-50 fixed ${isMobile ? 'left-4 right-4 bottom-20' : 'bottom-4 right-4'} transition-all duration-300 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      style={{ pointerEvents: isVisible ? 'auto' : 'none' }}
    >
      <div 
        className={`bg-white border border-border rounded-lg shadow-lg p-4 ${isMobile ? 'max-w-md mx-auto' : 'w-[28rem]'} ${isMobile ? 'flex flex-col' : ''}`}
      >
        <audio ref={audioRef} src={audioSrc} />
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">The Daily Rundown Podcast</h3>
          <Button 
            variant="ghost" 
            size="icon" 
            onClick={() => {
              setIsVisible(false);
              setTimeout(onClose, 300);
            }}
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
        <div className="mb-4" onClick={handleProgressClick}>
          <Progress value={progress} className="w-full cursor-pointer" />
        </div>
        <div className="flex justify-between text-sm text-muted-foreground mb-2">
          <span>{formatTime(duration * (progress / 100))}</span>
          <span>{formatTime(duration)}</span>
        </div>
        <div className="flex justify-between items-center">
          <Button onClick={togglePlayPause} size="icon" className="mr-2">
            {isPlaying ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
          </Button>
          <div className="flex space-x-1">
            <Button
              variant={speed === 1 ? "secondary" : "outline"}
              onClick={() => changeSpeed(1)}
              size="sm"
              className="px-2"
            >
              1x
            </Button>
            <Button
              variant={speed === 1.5 ? "secondary" : "outline"}
              onClick={() => changeSpeed(1.5)}
              size="sm"
              className="px-2"
            >
              1.5x
            </Button>
            <Button
              variant={speed === 1.75 ? "secondary" : "outline"}
              onClick={() => changeSpeed(1.75)}
              size="sm"
              className="px-2"
            >
              1.75x
            </Button>
            <Button
              variant={speed === 2 ? "secondary" : "outline"}
              onClick={() => changeSpeed(2)}
              size="sm"
              className="px-2"
            >
              2x
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}