import React, { useContext, useRef, useEffect } from "react";
import { ChatContext } from "contexts/ChatContext";
import { marked } from "marked";
import Feedback from "./components/Feedback";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useWebSocketMessage } from "contexts/Stream";
import CustomerInfoBox from "./components/CustomerInfoBox";

function ChatWindow() {
    const { messages, ghostMessage, currentCustomer, handleButtonClick } = useContext(ChatContext);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        console.log("ChatWindow: currentCustomer updated", currentCustomer);
    }, [currentCustomer]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useWebSocketMessage();

    return (
        <div className="vsa-messages-container">
            {/* {currentCustomer && (
                <CustomerInfoBox customerId={currentCustomer.custcd} customerName={currentCustomer.custname} />
            )} */}
            {messages.map((message, topindex) => (
                <div key={topindex} className={`vsa-${message.type}-message-container`}>
                    {message.text && message.type !== "middle" && (
                        <div className={`vsa-message vsa-${message.type}-message`}>
                            <div dangerouslySetInnerHTML={{ __html: marked(message.text).replace(/<p>|<\/p>|/g, "") }}></div>
                            {(message.type === 'ai' && topindex !== 0 && message.message_id) && 
                                <Feedback message={message} messageId={message.message_id} />
                            }
                        </div>
                    )}
                    {message.text && message.type === "middle" && (
                        <div className={`vsa-message vsa-middle-message`}>
                            <AutoAwesomeIcon style={{ height: '20px' }} />
                            <div dangerouslySetInnerHTML={{ __html: marked(message.text).replace(/<p>|<\/p>|/g, "") }}></div>
                        </div>
                    )}
                    {message.questions && Array.isArray(message.questions) &&
                        <div className="enum-options-button-container">
                            {message.questions.map((question, index) => (
                                <button className="enum-option-button" onClick={() => handleButtonClick(question)} key={index}>{question}</button>
                            ))}
                        </div>
                    }
                </div>
            ))}
            {ghostMessage && (
                <div>
                    <div className="vsa-bar-container">
                        <div className="vsa-bar vsa-bar-short"></div>
                        <div className="vsa-bar vsa-bar-long"></div>
                        <div className="vsa-bar vsa-bar-medium"></div>
                    </div>
                </div>
            )}
            <div ref={messagesEndRef} />
        </div>
    );
}

export default ChatWindow;
