import React from 'react';
import './globals.css';  
import { SidebarLayout } from 'components/catalyst/sidebar-layout';
import HomePage from 'pages/home/Index';
import { Text } from 'components/catalyst/text';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import CustomerTemplate from 'pages/customer-template/Index';
import NotesPage from 'pages/notes/Index';
import CustomersPage from 'pages/customers/Index';
import RemindersPage from 'pages/reminders/Index';
import FeedbackPage from 'pages/feedback/Index';
import ProfilePage from 'pages/profile/Index';
import PrivacyPolicyPage from 'pages/privacy-policy/Index';
import EmailPage from 'pages/email/Index';
import WaterCoolerPage from 'pages/watercooler/Index'
import DetailedPost from 'pages/watercooler/example/Index';

import LoginCallback from 'utils/LoginCallback';
import AuthGuard from 'utils/AuthGuard'

import { SidebarContent } from 'components/layout/SidebarContent'
import { NavbarContent } from 'components/layout/NavbarContent'
import { Header } from 'components/layout/Header'

import { DataProvider } from 'contexts/DataContext';
import { ChatProvider } from './contexts/ChatContext';
import { NotesProvider } from 'contexts/NotesContext';
import { RemindersProvider } from 'contexts/RemindersContext'; 
import SettingsPage from 'pages/watercooler/settings/Index';
import WCProfilePage from 'pages/watercooler/profile/Index';
import SavedPostsPage from 'pages/watercooler/saved/Index';
import FileUploadPage from 'pages/upload/Index';

function App() {
  return (
    <DataProvider>
      <ChatProvider>
        <NotesProvider>
          <RemindersProvider>
            <SidebarLayout navbar={<NavbarContent />} sidebar={<SidebarContent />}>
              <Header/>
              <Routes>
                <Route path="/login/callback" element={<LoginCallback />} />
                <Route
                  path="*"
                  element={
                    <AuthGuard>
                      <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/customer/:id" element={<CustomerTemplate />} />
                        <Route path="/customers" element={<CustomersPage />} />
                        <Route path="/reminders" element={<RemindersPage />} />
                        <Route path="/notes" element={<NotesPage />} />
                        <Route path="/feedback" element={<FeedbackPage />} />
                        <Route path="/profile" element={<ProfilePage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                        <Route path="/email" element={<EmailPage />} />
                        <Route path="/upload" element={<FileUploadPage />} />
                        <Route path="/water-cooler" element={<WaterCoolerPage />} />
                        <Route path="/wc-example" element={<DetailedPost />} />
                        <Route path="/wc-settings" element={<SettingsPage />} />
                        <Route path="/wc-profile" element={<WCProfilePage />} />
                        <Route path="/wc-saved" element={<SavedPostsPage />} />
                      </Routes>
                    </AuthGuard>
                  }
                />
              </Routes>
            </SidebarLayout>
          </RemindersProvider>
        </NotesProvider>
      </ChatProvider>
    </DataProvider>
  );
}

export default App;
